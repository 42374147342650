import { Button } from "Components/ui/button";
import PageHeader from "../../Components/common/pageHeader";
import AccordianCard from "../../Components/Cards/accordianCard";

function Faq() {
  const faq=[
    {
      question: "Is the Challenge fee a Monthly Subscription?",
      answer: "No, the challenge fee is not a monthly subscription. As one of the highest paying prop trading firms, we emphasize the importance of cultivating long-term, consistent traders. Our goal is not to encourage over-trading or excessive leverage. We believe in giving traders the time they need and removing unnecessary pressure to ensure their success.",
    },
    {
      question: "What is the Time Limit to Pass the Challenge?",
      answer: "Phase 1 and Phase 2 of the Challenge have no time limit. However, it's important to note that there is no time limit for the Live Funded Account. We believe in nurturing consistent and responsible risk managers, and imposing strict time constraints can lead to over-leveraging and over-trading. We want our traders to take their time, trade responsibly, and focus on developing a sustainable trading strategy. Rest assured, if you approach the Challenge with patience and discipline, you will have a smooth journey towards achieving your trading goals.",
    },
    {
      question: "Is the Funding Challenge a Demo Account?",
      answer: "Yes it is. To accurately assess your trading abilities, we provide a demo trading account in a risk free environment. The Trading Demo Account (Funding Challenge) operates exactly like a live account, ensuring a seamless transition when you start trading with real funds.",
    },
    {
      question: "Can I make cash with the Funding Challenge?",
      answer: "No, you cannot make cash with the Funding Challenge as it is conducted using a demo account. However, once you successfully pass the Funding Challenge, we provide you with a Live Account where you can trade with real funds and have plenty of opportunity to make real cash.",
    },
    {
      question: "Can I Trade my Own Strategies?",
      answer: "Yes, you are allowed to trade your own strategies. We provide flexibility for traders to employ their preferred trade methodologies. However, it is essential to ensure that your trading activities align with our established rules and guidelines. Adherence to our rules is necessary to maintain a consistent and compliant trading environment.",
    },
    {
      question: "Can I use Expert Agents, Bots and Algos?",
      answer: "Yes, you can use any mythology including EAs, bots, and algorithms in your trading. However, you are responsible for their operation and adherence to our rules. Malfunctioning or misbehaving EAs, bots, or algos cannot be used as an excuse for rule violations. Exercise caution and ensure they comply with our guidelines.",
    },
    {
      question: "What is the Leverage on the Accounts?",
      answer: "1:100 This controls the maximum exposure.",
    },
    {
      question: "What happens if I Breach the Rules?",
      answer: "In the event that you breach the rules, you have the option to reset the account back to the initial account balance. However, it is important to note that you will be required to pay the appropriate fee corresponding to the account size you are trading. Additionally, you have the flexibility to reset your account at any time if you choose to start anew from scratch.",
    },
    {
      question: "Do you allow Scalping, News Trading, Layering, Hedging, EA?",
      answer: "Yes, our prop trading firm does. There is no restriction on any methods trader’s desire.",
    },
    {
      question: "What happens when I reach the Profit Target?",
      answer: "Once you reach the Profit Target, simply notify us via chat (bottom right), email(support@tradersflow.co), or Facebook Messenger (www.facebook.com/tradersflowasia/). Our audit team will review your account, and if everything is in order, you will receive the 'Traders Agreement'. After returning the agreement, your Live Account will be set up within 2-3 business days, and the funds will be transferred accordingly.",
    },
    {
      question: "Can I use my Broker?",
      answer: "No, as a Prop Trading Firm, you will be trading our accounts exclusively. We have our own designated Liquidity Provider, and you will be given the account details to conduct your trading activities.",
    },
    {
      question: "Can I have More than One Funding Challenge?",
      answer: "Yes, you can have as many challenge accounts as you like.",
    },
    {
      question: "Is our prop trading firm regulated?",
      answer: "Our company places a high priority on regulatory and compliance matters to ensure that we comply with applicable laws and regulatory requirements. We adhere to the regulations set forth by relevant financial regulatory bodies and take necessary measures to protect the rights and funds of our clients. Specifically, we collaborate with the relevant regulatory authorities and hold the necessary licenses or permits to conduct our business. Our trading activities and operations follow strict compliance procedures and internal controls to ensure transparency, fairness, and the protection of client interests. We undergo regular internal and external audits to ensure that our operations comply with regulatory standards. We are dedicated to establishing a robust compliance framework and continuously improving our compliance practices to adapt to evolving regulatory environments and best practices. If you have more specific questions regarding regulation and compliance, we are willing to provide further detailed information and address them. We encourage clients to inquire about our compliance status, ensuring they have a comprehensive understanding and confidence in our business operations and risk management.",
    },
  ];

  const handleEmailClick = () => {
    const emailAddress = 'support@tradersflow.co';
    const subject = '';
    const body = '';

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the default email client
    window.location.href = mailtoLink;
  };

  return (
    <>
      <div className="Payout-page">
        <div className="header items-center">
          <div className="left-section">
          <PageHeader
            title={'Common Question'}
          />
          
          </div>
          {/* <Button type="primary" className="custom-primary-button px-6 py-5 rounded-xl" onClick={handleEmailClick}>Customer Support</Button> */}
          <Button 
            type="primary" 
            className="custom-primary-button px-6 py-5 rounded-xl" 
            onClick={() => window.open("https://intercom.help/tradersflow/en/collections/7049008-general-faq-account-information")}
          >
            More
          </Button>
        </div>
        {/* <Row gutter={16}>
          {faq.map((data,i)=>{
            return(
              <Col xs={24} xl={12}>
                <AccordianCard question={data.question} answer={data.answer} key={i}/>
              </Col>
            )
          })}
        </Row> */}
      
        <div className="flex flex-wrap">
          <div className="w-full xl:w-2/4 pr-4">
            {faq.map((data,i)=>{
              if ((i+1)%2 !== 0) {
                return(
                  <div className="pb-3" key={(i+1)}>
                    <AccordianCard question={data.question} answer={data.answer} index={i}/>
                  </div>
                )
              }
            })}
          </div>
          <div className="w-full xl:w-2/4 pl-4">
            {faq.map((data,i)=>{
              if ((i+1)%2 === 0) {
                return(
                  <div className="pb-3" key={(i+1)}>
                    <AccordianCard question={data.question} answer={data.answer} key={i}/>
                  </div>
                )
              }
            })}
          </div>
        </div>
        
      </div>
    </>
  );
}


export default Faq;
