// Header.js

import React from 'react';
import { Card, Space, Statistic, Table, Typography,Radio,Row,Col,Button,Image ,Input,Select, Divider,Checkbox,Form} from "antd";

const { Option } = Select;
const CryptoPaymentForm = ({onFinish}) => {
    return(
        <>
         <Form
    name="card_payment_form"
    onFinish={onFinish}
    initialValues={{ remember: true }}
    layout="vertical"
  >
   
    <Row gutter={16}>
     
      <Col span={24}>
        <Form.Item
          label="Your Cryptocurrency *"
          name="Currency"
          rules={[{ required: true, message: 'Your Cryptocurrency ' }]}
        >
          <Select className="input-text ant-select">
            {/* Add country options */}
            <Option value="usa">USA</Option>
            <Option value="canada">Canada</Option>
            {/* Add more options as needed */}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Network Option *"
          name="network"
          rules={[{ required: true, message: 'Your Cryptocurrency ' }]}
        >
          <Select className="input-text ant-select">
            {/* Add country options */}
            <Option value="usa">USA</Option>
            <Option value="canada">Canada</Option>
            {/* Add more options as needed */}
          </Select>
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col span={24}>
        <label>
          
        To learn the full process of crypto payment please visit our FAQ. Your payment status will be updated on the Billing page. After the successful payment, you will receive an email.
            </label>
      </Col>
    </Row>

    <Form.Item>
      <Button type="primary" className="custom-primary-button h-50 d-flex justify-content-between align-items-center " htmlType="submit">
      Next <span>
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
  <path d="M16.2339 6.6709L23.0626 13.4996L16.2339 20.3284" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.9375 13.5H22.8712" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

      </span>
      </Button>
    </Form.Item>
  </Form>
        
        </>

    )
   
 
};

export default CryptoPaymentForm;
