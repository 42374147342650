import React from "react";

export const Dashboard = ({ className, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.83 8.01002L14.28 2.77002C13 1.75002 11 1.74002 9.72996 2.76002L3.17996 8.01002C2.23996 8.76002 1.66996 10.26 1.86996 11.44L3.12996 18.98C3.41996 20.67 4.98996 22 6.69996 22H17.3C18.99 22 20.59 20.64 20.88 18.97L22.14 11.43C22.32 10.26 21.75 8.76002 20.83 8.01002ZM12.75 18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18Z"
      fill={fill}
    />
  </svg>
);
export const TradingJournal = ({ className, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.5 16V18.5C20.5 20.43 18.93 22 17 22H7C5.07 22 3.5 20.43 3.5 18.5V17.85C3.5 16.28 4.78 15 6.35 15H19.5C20.05 15 20.5 15.45 20.5 16Z"
      fill={fill}
    />
    <path
      d="M15.5 2H8.5C4.5 2 3.5 3 3.5 7V14.58C4.26 13.91 5.26 13.5 6.35 13.5H19.5C20.05 13.5 20.5 13.05 20.5 12.5V7C20.5 3 19.5 2 15.5 2ZM13 10.75H8C7.59 10.75 7.25 10.41 7.25 10C7.25 9.59 7.59 9.25 8 9.25H13C13.41 9.25 13.75 9.59 13.75 10C13.75 10.41 13.41 10.75 13 10.75ZM16 7.25H8C7.59 7.25 7.25 6.91 7.25 6.5C7.25 6.09 7.59 5.75 8 5.75H16C16.41 5.75 16.75 6.09 16.75 6.5C16.75 6.91 16.41 7.25 16 7.25Z"
      fill={fill}
    />
  </svg>
);
export const Matrix = ({ className, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 8.75C11.59 8.75 11.25 8.41 11.25 8C11.25 7.59 11.59 7.25 12 7.25C14.62 7.25 16.75 9.38 16.75 12C16.75 14.62 14.62 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16C11.25 15.59 11.59 15.25 12 15.25C13.79 15.25 15.25 13.79 15.25 12C15.25 10.21 13.79 8.75 12 8.75ZM12 19.75C7.73 19.75 4.25 16.27 4.25 12C4.25 11.59 4.59 11.25 5 11.25C5.41 11.25 5.75 11.59 5.75 12C5.75 15.45 8.55 18.25 12 18.25C15.45 18.25 18.25 15.45 18.25 12C18.25 8.55 15.45 5.75 12 5.75C11.59 5.75 11.25 5.41 11.25 5C11.25 4.59 11.59 4.25 12 4.25C16.27 4.25 19.75 7.73 19.75 12C19.75 16.27 16.27 19.75 12 19.75Z"
      fill={fill}
    />
  </svg>
);
export const TradingPlatform = ({ className, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM9.91 16.19C9.91 16.83 9.39 17.35 8.74 17.35C8.1 17.35 7.58 16.83 7.58 16.19V12.93C7.58 12.29 8.1 11.77 8.74 11.77C9.39 11.77 9.91 12.29 9.91 12.93V16.19ZM16.42 16.19C16.42 16.83 15.9 17.35 15.26 17.35C14.61 17.35 14.09 16.83 14.09 16.19V7.81C14.09 7.17 14.61 6.65 15.26 6.65C15.9 6.65 16.42 7.17 16.42 7.81V16.19Z"
      fill={fill}
    />
  </svg>
);
export const Reset = ({ className, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM12 18.58C8.83 18.58 6.25 16 6.25 12.83C6.25 9.72 8.73 7.19 11.81 7.08L11.44 6.65C11.17 6.34 11.2 5.86 11.51 5.59C11.82 5.32 12.3 5.35 12.57 5.66L14.23 7.56C14.25 7.58 14.26 7.6 14.28 7.62C14.32 7.68 14.36 7.75 14.38 7.82C14.39 7.86 14.4 7.9 14.41 7.94C14.43 8.03 14.42 8.13 14.4 8.22C14.38 8.3 14.35 8.38 14.31 8.46C14.3 8.48 14.28 8.5 14.27 8.52C14.23 8.58 14.17 8.63 14.11 8.67C14.11 8.67 14.1 8.67 14.1 8.68L12.16 10.1C11.82 10.34 11.36 10.27 11.11 9.94C10.87 9.61 10.94 9.14 11.27 8.89L11.68 8.59C9.48 8.75 7.74 10.59 7.74 12.83C7.74 15.17 9.65 17.08 11.99 17.08C14.33 17.08 16.24 15.17 16.24 12.83C16.24 11.99 15.99 11.17 15.53 10.47C15.3 10.13 15.39 9.66 15.74 9.43C16.09 9.2 16.55 9.29 16.78 9.64C17.41 10.59 17.75 11.69 17.75 12.83C17.75 16 15.17 18.58 12 18.58Z"
      fill={fill}
    />
  </svg>
);
export const Billing = ({ className, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M7 2H6C3 2 2 3.79 2 6V7V21C2 21.83 2.94 22.3 3.6 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.29 22.29C8.68 22.68 9.32 22.68 9.71 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7ZM5.97 14.01C5.42 14.01 4.97 13.56 4.97 13.01C4.97 12.46 5.42 12.01 5.97 12.01C6.52 12.01 6.97 12.46 6.97 13.01C6.97 13.56 6.52 14.01 5.97 14.01ZM5.97 10.01C5.42 10.01 4.97 9.56 4.97 9.01C4.97 8.46 5.42 8.01 5.97 8.01C6.52 8.01 6.97 8.46 6.97 9.01C6.97 9.56 6.52 10.01 5.97 10.01ZM12 13.76H9C8.59 13.76 8.25 13.42 8.25 13.01C8.25 12.6 8.59 12.26 9 12.26H12C12.41 12.26 12.75 12.6 12.75 13.01C12.75 13.42 12.41 13.76 12 13.76ZM12 9.76H9C8.59 9.76 8.25 9.42 8.25 9.01C8.25 8.6 8.59 8.26 9 8.26H12C12.41 8.26 12.75 8.6 12.75 9.01C12.75 9.42 12.41 9.76 12 9.76Z"
      fill={fill}
    />
    <path
      d="M18.01 2V3.5C18.67 3.5 19.3 3.77 19.76 4.22C20.24 4.71 20.5 5.34 20.5 6V8.42C20.5 9.16 20.17 9.5 19.42 9.5H17.5V4.01C17.5 3.73 17.73 3.5 18.01 3.5V2ZM18.01 2C16.9 2 16 2.9 16 4.01V11H19.42C21 11 22 10 22 8.42V6C22 4.9 21.55 3.9 20.83 3.17C20.1 2.45 19.11 2.01 18.01 2C18.02 2 18.01 2 18.01 2Z"
      fill={fill}
    />
  </svg>
);
export const Payout = ({ className, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10.25 10.03C10.25 10.57 10.4 10.65 10.74 10.77L11.25 10.95V9.25H10.95C10.57 9.25 10.25 9.6 10.25 10.03Z"
      fill={fill}
    />
    <path
      d="M12.75 14.75H13.05C13.44 14.75 13.75 14.4 13.75 13.97C13.75 13.43 13.6 13.35 13.26 13.23L12.75 13.05V14.75Z"
      fill={fill}
    />
    <path
      d="M19.58 5.48L17.53 7.53C17.38 7.68 17.19 7.75 17 7.75C16.81 7.75 16.62 7.68 16.47 7.53C16.18 7.24 16.18 6.76 16.47 6.47L18.52 4.42C16.76 2.92 14.49 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 9.51 21.08 7.24 19.58 5.48ZM13.75 11.82C14.39 12.05 15.25 12.51 15.25 13.98C15.25 15.23 14.26 16.26 13.05 16.26H12.75V16.51C12.75 16.92 12.41 17.26 12 17.26C11.59 17.26 11.25 16.92 11.25 16.51V16.26H11.17C9.84 16.26 8.75 15.14 8.75 13.76C8.75 13.34 9.09 13 9.5 13C9.91 13 10.25 13.34 10.25 13.75C10.25 14.3 10.66 14.75 11.17 14.75H11.25V12.53L10.25 12.18C9.61 11.95 8.75 11.49 8.75 10.02C8.75 8.77 9.74 7.74 10.95 7.74H11.25V7.5C11.25 7.09 11.59 6.75 12 6.75C12.41 6.75 12.75 7.09 12.75 7.5V7.75H12.83C14.16 7.75 15.25 8.87 15.25 10.25C15.25 10.66 14.91 11 14.5 11C14.09 11 13.75 10.66 13.75 10.25C13.75 9.7 13.34 9.25 12.83 9.25H12.75V11.47L13.75 11.82Z"
      fill={fill}
    />
    <path
      d="M22.69 1.71C22.61 1.53 22.47 1.38 22.28 1.3C22.19 1.27 22.1 1.25 22 1.25H18C17.59 1.25 17.25 1.59 17.25 2C17.25 2.41 17.59 2.75 18 2.75H20.19L18.52 4.42C18.9 4.75 19.25 5.1 19.58 5.48L21.25 3.81V6C21.25 6.41 21.59 6.75 22 6.75C22.41 6.75 22.75 6.41 22.75 6V2C22.75 1.9 22.73 1.81 22.69 1.71Z"
      fill={fill}
    />
  </svg>
);
export const Certificate = ({ className, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21.25 18.4701L19.6 18.8601C19.23 18.9501 18.94 19.2301 18.86 19.6001L18.51 21.0701C18.32 21.8701 17.3 22.1201 16.77 21.4901L13.78 18.0501C13.54 17.7701 13.67 17.3301 14.03 17.2401C15.8 16.8101 17.39 15.8201 18.56 14.4101C18.75 14.1801 19.09 14.1501 19.3 14.3601L21.52 16.5801C22.28 17.3401 22.01 18.2901 21.25 18.4701Z"
      fill={fill}
    />
    <path
      d="M2.70004 18.4701L4.35004 18.8601C4.72004 18.9501 5.01004 19.2301 5.09004 19.6001L5.44004 21.0701C5.63004 21.8701 6.65004 22.1201 7.18004 21.4901L10.17 18.0501C10.41 17.7701 10.28 17.3301 9.92004 17.2401C8.15004 16.8101 6.56004 15.8201 5.39004 14.4101C5.20004 14.1801 4.86004 14.1501 4.65004 14.3601L2.43004 16.5801C1.67004 17.3401 1.94004 18.2901 2.70004 18.4701Z"
      fill={fill}
    />
    <path
      d="M12 2C8.13 2 5 5.13 5 9C5 10.45 5.43 11.78 6.17 12.89C7.25 14.49 8.96 15.62 10.95 15.91C11.29 15.97 11.64 16 12 16C12.36 16 12.71 15.97 13.05 15.91C15.04 15.62 16.75 14.49 17.83 12.89C18.57 11.78 19 10.45 19 9C19 5.13 15.87 2 12 2ZM15.06 8.78L14.23 9.61C14.09 9.75 14.01 10.02 14.06 10.22L14.3 11.25C14.49 12.06 14.06 12.38 13.34 11.95L12.34 11.36C12.16 11.25 11.86 11.25 11.68 11.36L10.68 11.95C9.96 12.37 9.53 12.06 9.72 11.25L9.96 10.22C10 10.03 9.93 9.75 9.79 9.61L8.94 8.78C8.45 8.29 8.61 7.8 9.29 7.69L10.36 7.51C10.54 7.48 10.75 7.32 10.83 7.16L11.42 5.98C11.74 5.34 12.26 5.34 12.58 5.98L13.17 7.16C13.25 7.32 13.46 7.48 13.65 7.51L14.72 7.69C15.39 7.8 15.55 8.29 15.06 8.78Z"
      fill={fill}
    />
  </svg>
);
export const Competition = ({ className, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M6.67 14H4C2.9 14 2 14.9 2 16V21C2 21.55 2.45 22 3 22H6.67C7.22 22 7.67 21.55 7.67 21V15C7.67 14.45 7.22 14 6.67 14Z"
      fill={fill}
    />
    <path
      d="M13.33 10H10.66C9.56003 10 8.66003 10.9 8.66003 12V21C8.66003 21.55 9.11003 22 9.66003 22H14.33C14.88 22 15.33 21.55 15.33 21V12C15.33 10.9 14.44 10 13.33 10Z"
      fill={fill}
    />
    <path
      d="M20 17H17.33C16.78 17 16.33 17.45 16.33 18V21C16.33 21.55 16.78 22 17.33 22H21C21.55 22 22 21.55 22 21V19C22 17.9 21.1 17 20 17Z"
      fill={fill}
    />
    <path
      d="M15.0099 4.84998C15.3199 4.53998 15.4399 4.16998 15.3399 3.84998C15.2399 3.52998 14.9299 3.29998 14.4899 3.22998L13.5299 3.06998C13.4899 3.06998 13.3999 2.99998 13.3799 2.95998L12.8499 1.89998C12.4499 1.08998 11.5399 1.08998 11.1399 1.89998L10.6099 2.95998C10.5999 2.99998 10.5099 3.06998 10.4699 3.06998L9.50994 3.22998C9.06994 3.29998 8.76994 3.52998 8.65994 3.84998C8.55994 4.16998 8.67994 4.53998 8.98994 4.84998L9.72994 5.59998C9.76994 5.62998 9.79994 5.74998 9.78994 5.78998L9.57994 6.70998C9.41994 7.39998 9.67994 7.70998 9.84994 7.82998C10.0199 7.94998 10.3899 8.10998 10.9999 7.74998L11.8999 7.21998C11.9399 7.18998 12.0699 7.18998 12.1099 7.21998L12.9999 7.74998C13.2799 7.91998 13.5099 7.96998 13.6899 7.96998C13.8999 7.96998 14.0499 7.88998 14.1399 7.82998C14.3099 7.70998 14.5699 7.39998 14.4099 6.70998L14.1999 5.78998C14.1899 5.73998 14.2199 5.62998 14.2599 5.59998L15.0099 4.84998Z"
      fill={fill}
    />
  </svg>
);
export const Education = ({ className, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16.83 15.6399C17.5 15.1999 18.38 15.6799 18.38 16.4799V17.7699C18.38 19.0399 17.39 20.3999 16.2 20.7999L13.01 21.8599C12.45 22.0499 11.54 22.0499 10.99 21.8599L7.8 20.7999C6.6 20.3999 5.62 19.0399 5.62 17.7699V16.4699C5.62 15.6799 6.5 15.1999 7.16 15.6299L9.22 16.9699C10.01 17.4999 11.01 17.7599 12.01 17.7599C13.01 17.7599 14.01 17.4999 14.8 16.9699L16.83 15.6399Z"
      fill={fill}
    />
    <path
      d="M19.98 6.46006L13.99 2.53006C12.91 1.82006 11.13 1.82006 10.05 2.53006L4.03002 6.46006C2.10002 7.71006 2.10002 10.5401 4.03002 11.8001L5.63002 12.8401L10.05 15.7201C11.13 16.4301 12.91 16.4301 13.99 15.7201L18.38 12.8401L19.75 11.9401V15.0001C19.75 15.4101 20.09 15.7501 20.5 15.7501C20.91 15.7501 21.25 15.4101 21.25 15.0001V10.0801C21.65 8.79006 21.24 7.29006 19.98 6.46006Z"
      fill={fill}
    />
  </svg>
);
export const AffiliatePortal = ({ className, fill }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3601 12.7301C19.9901 12.7301 19.6801 12.4501 19.6401 12.0801C19.4001 9.88007 18.2201 7.90007 16.4001 6.64007C16.0701 6.41007 15.9901 5.96007 16.2201 5.63007C16.4501 5.30007 16.9001 5.22007 17.2301 5.45007C19.4001 6.96007 20.8001 9.32007 21.0901 11.9301C21.1301 12.3301 20.8401 12.6901 20.4401 12.7301C20.4101 12.7301 20.3901 12.7301 20.3601 12.7301Z"
      fill={fill}
    />
    <path
      d="M3.74029 12.7802C3.72029 12.7802 3.69029 12.7802 3.67029 12.7802C3.27029 12.7402 2.98029 12.3802 3.02029 11.9802C3.29029 9.3702 4.67029 7.0102 6.82029 5.4902C7.14029 5.2602 7.60029 5.3402 7.83029 5.6602C8.06029 5.9902 7.98029 6.4402 7.66029 6.6702C5.86029 7.9502 4.69029 9.9302 4.47029 12.1202C4.43029 12.5002 4.11029 12.7802 3.74029 12.7802Z"
      fill={fill}
    />
    <path
      d="M15.9896 21.0998C14.7596 21.6898 13.4396 21.9898 12.0596 21.9898C10.6196 21.9898 9.24961 21.6698 7.96961 21.0198C7.60961 20.8498 7.46961 20.4098 7.64961 20.0498C7.81961 19.6898 8.25961 19.5498 8.61961 19.7198C9.24961 20.0398 9.91961 20.2598 10.5996 20.3898C11.5196 20.5698 12.4596 20.5798 13.3796 20.4198C14.0596 20.2998 14.7296 20.0898 15.3496 19.7898C15.7196 19.6198 16.1596 19.7598 16.3196 20.1298C16.4996 20.4898 16.3596 20.9298 15.9896 21.0998Z"
      fill={fill}
    />
    <path
      d="M12.0505 2.00977C10.5005 2.00977 9.23047 3.26977 9.23047 4.82977C9.23047 6.38977 10.4905 7.64977 12.0505 7.64977C13.6105 7.64977 14.8705 6.38977 14.8705 4.82977C14.8705 3.26977 13.6105 2.00977 12.0505 2.00977Z"
      fill={fill}
    />
    <path
      d="M5.05047 13.8701C3.50047 13.8701 2.23047 15.1301 2.23047 16.6901C2.23047 18.2501 3.49047 19.5101 5.05047 19.5101C6.61047 19.5101 7.87047 18.2501 7.87047 16.6901C7.87047 15.1301 6.60047 13.8701 5.05047 13.8701Z"
      fill={fill}
    />
    <path
      d="M18.9499 13.8701C17.3999 13.8701 16.1299 15.1301 16.1299 16.6901C16.1299 18.2501 17.3899 19.5101 18.9499 19.5101C20.5099 19.5101 21.7699 18.2501 21.7699 16.6901C21.7699 15.1301 20.5099 13.8701 18.9499 13.8701Z"
      fill={fill}
    />
  </svg>
);
export const Faq = ({ className, fill }) => {
  console.log("fill", fill);
  return (
    <svg
      id="faq"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1_4870)">
        <path
          d="M18 0H10C6 0 4 2 4 6V19C4 19.55 4.45 20 5 20H18C22 20 24 18 24 14V6C24 2 22 0 18 0Z"
          fill={fill}
        />
        <path
          d="M12.8795 11.6862V11.6007C12.8855 11.0432 12.941 10.5995 13.0459 10.2698C13.1538 9.94007 13.3067 9.67328 13.5045 9.46944C13.7023 9.2656 13.9407 9.07975 14.2194 8.91189C14.3993 8.79798 14.5612 8.67058 14.705 8.52969C14.8489 8.3888 14.9628 8.22693 15.0468 8.04408C15.1307 7.86122 15.1727 7.65888 15.1727 7.43706C15.1727 7.17027 15.1097 6.93946 14.9838 6.74461C14.8579 6.54977 14.6901 6.39989 14.4802 6.29497C14.2734 6.18705 14.0426 6.1331 13.7878 6.1331C13.557 6.1331 13.3366 6.18106 13.1268 6.27698C12.917 6.37291 12.7431 6.52279 12.6052 6.72663C12.4673 6.92747 12.3879 7.18676 12.3669 7.50451H11C11.021 6.96494 11.1574 6.5093 11.4092 6.13759C11.661 5.76289 11.9937 5.47962 12.4074 5.28777C12.824 5.09592 13.2842 5 13.7878 5C14.3393 5 14.822 5.10342 15.2356 5.31025C15.6493 5.51409 15.97 5.80036 16.1979 6.16907C16.4287 6.53478 16.5441 6.96194 16.5441 7.45055C16.5441 7.78628 16.4916 8.08904 16.3867 8.35883C16.2818 8.62561 16.1319 8.86392 15.9371 9.07376C15.7452 9.28359 15.5144 9.46944 15.2446 9.63131C14.9898 9.79019 14.783 9.95506 14.6241 10.1259C14.4682 10.2968 14.3543 10.4991 14.2824 10.7329C14.2104 10.9667 14.1715 11.256 14.1655 11.6007V11.6862H12.8795ZM13.5585 14.42C13.3127 14.42 13.1013 14.3331 12.9245 14.1592C12.7476 13.9823 12.6592 13.7695 12.6592 13.5207C12.6592 13.2749 12.7476 13.0651 12.9245 12.8912C13.1013 12.7144 13.3127 12.6259 13.5585 12.6259C13.8013 12.6259 14.0111 12.7144 14.188 12.8912C14.3678 13.0651 14.4577 13.2749 14.4577 13.5207C14.4577 13.6856 14.4158 13.837 14.3318 13.9749C14.2509 14.1097 14.143 14.2177 14.0081 14.2986C13.8732 14.3795 13.7233 14.42 13.5585 14.42Z"
          fill={fill === "#717184" ? "#fff" : "#DF1044"}
        />
      </g>
    </svg>
  );
};
