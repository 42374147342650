// Header.js

import React from 'react';
import { Layout, Dropdown, Menu, Button, Avatar, Image, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles

const WelcomeBanner = ({ title, subheading, reset, tradeDetail, showTradeDetailModalFunction, symbol, ticket}) => {
  return (
    <div className='Welcome-banner'>
      {tradeDetail ? (
        // Render trade-detail-container when tradeDetail exists
        <div className='trade-detail-container'>
          <div className='trade-detail'>
            <h1>{symbol}</h1>
            <p>#{ticket}</p>
          </div>
          <Button className='custom-primary-button h-50' onClick={showTradeDetailModalFunction}>
            <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M20.36 12.7301C19.99 12.7301 19.68 12.4501 19.64 12.0801C19.4 9.88007 18.22 7.90007 16.4 6.64007C16.07 6.41007 15.99 5.96007 16.22 5.63007C16.45 5.30007 16.9 5.22007 17.23 5.45007C19.4 6.96007 20.8 9.32007 21.09 11.9301C21.13 12.3301 20.84 12.6901 20.44 12.7301C20.41 12.7301 20.39 12.7301 20.36 12.7301Z" fill="white"/>
              <path d="M3.73998 12.7802C3.71998 12.7802 3.68998 12.7802 3.66998 12.7802C3.26998 12.7402 2.97998 12.3802 3.01998 11.9802C3.28998 9.3702 4.66998 7.0102 6.81998 5.4902C7.13998 5.2602 7.59998 5.3402 7.82998 5.6602C8.05998 5.9902 7.97998 6.4402 7.65998 6.6702C5.85998 7.9502 4.68998 9.9302 4.46998 12.1202C4.42998 12.5002 4.10998 12.7802 3.73998 12.7802Z" fill="white"/>
              <path d="M15.99 21.0998C14.76 21.6898 13.44 21.9898 12.06 21.9898C10.62 21.9898 9.24998 21.6698 7.96998 21.0198C7.60998 20.8498 7.46998 20.4098 7.64998 20.0498C7.81998 19.6898 8.25998 19.5498 8.61998 19.7198C9.24998 20.0398 9.91998 20.2598 10.6 20.3898C11.52 20.5698 12.46 20.5798 13.38 20.4198C14.06 20.2998 14.73 20.0898 15.35 19.7898C15.72 19.6198 16.16 19.7598 16.32 20.1298C16.5 20.4898 16.36 20.9298 15.99 21.0998Z" fill="white"/>
              <path d="M12.05 2.00977C10.5 2.00977 9.22998 3.26977 9.22998 4.82977C9.22998 6.38977 10.49 7.64977 12.05 7.64977C13.61 7.64977 14.87 6.38977 14.87 4.82977C14.87 3.26977 13.61 2.00977 12.05 2.00977Z" fill="white"/>
              <path d="M5.04998 13.8701C3.49998 13.8701 2.22998 15.1301 2.22998 16.6901C2.22998 18.2501 3.48998 19.5101 5.04998 19.5101C6.60998 19.5101 7.86998 18.2501 7.86998 16.6901C7.86998 15.1301 6.59998 13.8701 5.04998 13.8701Z" fill="white"/>
              <path d="M18.95 13.8701C17.4 13.8701 16.13 15.1301 16.13 16.6901C16.13 18.2501 17.39 19.5101 18.95 19.5101C20.51 19.5101 21.77 18.2501 21.77 16.6901C21.77 15.1301 20.51 13.8701 18.95 13.8701Z" fill="white"/>
            </svg>
            </span> Share
          </Button>
        </div>
      ) : (
        // Render internal-content when tradeDetail does not exist
        <div className='internal-content'>
          {reset && (
            <>
              <div className='reset-heading'>
                <div className='box'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM12 18.58C8.83 18.58 6.25 16 6.25 12.83C6.25 9.72 8.73 7.19 11.81 7.08L11.44 6.65C11.17 6.34 11.2 5.86 11.51 5.59C11.82 5.32 12.3 5.35 12.57 5.66L14.23 7.56C14.25 7.58 14.26 7.6 14.28 7.62C14.32 7.68 14.36 7.75 14.38 7.82C14.39 7.86 14.4 7.9 14.41 7.94C14.43 8.03 14.42 8.13 14.4 8.22C14.38 8.3 14.35 8.38 14.31 8.46C14.3 8.48 14.28 8.5 14.27 8.52C14.23 8.58 14.17 8.63 14.11 8.67C14.11 8.67 14.1 8.67 14.1 8.68L12.16 10.1C11.82 10.34 11.36 10.27 11.11 9.94C10.87 9.61 10.94 9.14 11.27 8.89L11.68 8.59C9.48 8.75 7.74 10.59 7.74 12.83C7.74 15.17 9.65 17.08 11.99 17.08C14.33 17.08 16.24 15.17 16.24 12.83C16.24 11.99 15.99 11.17 15.53 10.47C15.3 10.13 15.39 9.66 15.74 9.43C16.09 9.2 16.55 9.29 16.78 9.64C17.41 10.59 17.75 11.69 17.75 12.83C17.75 16 15.17 18.58 12 18.58Z" fill="white"/>
                  </svg>
                </div>
                Reset
              </div>
             
              {/* Reset content */}
              <p>If a trader violates the rules his/her account will be suspended...</p>
              <p className='reset-note'>Note: You must close the running trades if you have any</p>
              <Button className='custom-primary-button h-50'>Reset Request</Button>
            </>
          )}
          <h1>{title}</h1>
          <p>{subheading}</p>
        </div>
      )}
    </div>
  );
};

export default WelcomeBanner;
