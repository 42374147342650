import { useEffect, useState } from "react";
import { Card, Space, Statistic, Table, Typography, Radio, Row, Col, Image, Input, Select, Divider, notification} from "antd";
import PageHeader from "../../Components/common/pageHeader";
import WelcomeBanner from "../../Components/common/WelcomeBanner";
import StatsCard from "../../Components/Cards/statsCard";
import { Form } from 'antd';
import { useLocation } from "react-router-dom";
import { Button } from "Components/ui/button";
import { 
  getOneTradingNote,
  createTradingNote,
  updateTradingNote,
  getOpenOrderRecord,
  getClosedOrderRecord
} from "../../API";
import ShareTradeDetailModal from "../../Components/Modals/shareTradeDetailModal";
import dayjs from "dayjs";
// import { genActionStyle } from "antd/es/alert/style";


function TradeDetail() {
  const countdownTime = 'Feb 4, 2024 12:00:00'; // Example time, update it with your desired time
  const gmt = '+00:00'; // Example GMT, update it with your desired GMT 

  const [submitAction, setSubmitAction] = useState("create");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deal = queryParams.get('deal');
  const accountLogin = queryParams.get('accountLogin');
  const accountId = queryParams.get('accountId');
  const type = queryParams.get('type');
  const [tradingNoteData, setTradingNoteData] = useState({});
  const [recordDetail, setRecordDetail] = useState({});

  const [formTradingNote] = Form.useForm();
  const [toast, contextHolder] = notification.useNotification();

  const [isShowShareTradeDetailModal, setIsShowShareTradeDetailModal] = useState(false);
  const openWithdrawFormModal = () => {
    setIsShowShareTradeDetailModal(true);
  };

  const closeShareTradeDetailModal = () => {
    setIsShowShareTradeDetailModal(false);
  };

  console.log('recordDetail', recordDetail)

  const detailStats = [
    {
      heading: 'Net P&L',
      value: recordDetail?.[0]?.profit || '$0.00',
    },
    {
      heading: 'Volume',
      value: recordDetail?.[0]?.volume ? recordDetail?.[0]?.volume/10000 : '-',
    },
    {
      heading: 'Side',
      value: recordDetail?.[0]?.action === 0 ? "Buy" : "Sell",
    },
    {
      heading: 'Symbol',
      value: recordDetail?.[0]?.symbol ?? '-',
    },
    {
      heading: 'Open Time',
      value: recordDetail?.[0]?.time_create
      ? dayjs(recordDetail?.[0]?.time_create * 1000).format("YYYY-MM-DD hh:mmA")
      : "-",
    },
    {
      heading: 'Close Time',
      value: recordDetail?.[0]?.time
      ? dayjs(recordDetail?.[0]?.time * 1000).format("YYYY-MM-DD hh:mmA")
      : "-",
    },
    {
      heading: 'Open Price',
      value: recordDetail?.[0]?.price_open ? (Math.floor(recordDetail?.[0]?.price_open * 100) / 100).toFixed(2) : '-',
    },
    {
      heading: 'Close Price',
      value: recordDetail?.[0]?.price ? (Math.floor(recordDetail?.[0]?.price * 100) / 100).toFixed(2) : '-',
    },
  
  ];
  

  const onFinish = async (values) => {
    let postObj;
    switch(submitAction){
      case "create":
        postObj = {
          account_id: accountId,
          deal: deal,
          setup: values?.setup ?? "",
          mistake: values?.mistake ?? "",
          others: values?.others ?? "",
        };  
        try {
          let result = await createTradingNote(postObj);
          if (result) {
            toast.success({
              message: "",
              description:
                'Trading note creation successfully.',
              placement: "top",
            });
            setTradingNoteData(result ?? {});
            setSubmitAction("update");
          }
        } catch(error) {
          toast.error({
            message: "",
            description:
              'Trading note creation failed!',
            placement: "top",
          });
        }
        break;
      case "update": 
        postObj = {
          account_id: accountId,
          deal: deal,
          setup: values?.setup ?? "",
          mistake: values?.mistake ?? "",
          others: values?.others ?? "",
          trading_note_id: tradingNoteData?.id ?? "",
        }; 
        try {
          let result = await updateTradingNote(postObj);
          let isSuccess = result?.success ?? false;
          if (isSuccess) {
            toast.success({
              message: "",
              description:
                'Update trading note successfully.',
              placement: "top",
            });
          }
        } catch(error) {
          toast.error({
            message: "",
            description:
              'Failed to update trading note.',
            placement: "top",
          });
        }
        break;
      default:
        break;
    }
  };

  const getOneTradingNoteData = async () => {
    try {
      const data = await getOneTradingNote(deal, accountId);
      let isSuccess = data?.success ?? false;
      if (isSuccess) {
        setSubmitAction("update");
        setTradingNoteData(data?.trading_note ?? {});
        formTradingNote.setFieldsValue({
          setup: data?.trading_note?.setup ?? "",
          mistake: data?.trading_note?.mistake ?? "", 
          others: data?.trading_note?.others ?? "", 
        });
      }
    } catch (error) {
        console.error('Error fetching One Trading Note data:', error);
    }
  }
  
  const getRecord = async () => {
    try {
      if (type === "open") {
        const data = await getOpenOrderRecord(accountId);
        const selectedRecord = data.orders.filter(item => item.position == deal);
        setRecordDetail(selectedRecord);
      } else if (type === "closed") {
        const data = await getClosedOrderRecord(accountId);
        const selectedRecord = data.orders.filter(item => item.deal == deal);
        setRecordDetail(selectedRecord);
      }
    } catch (error) {
      console.error('Error fetching record detail:', error);
    }
  }
  useEffect(() => {
    getOneTradingNoteData();
    getRecord();
  }, []);



  return (
    <>
      {contextHolder}
      <div className="Matrix-page">
        <div className="header">
          <div className="left-section">
          <PageHeader
          title={'Trade Detail'}
          />
          </div>
        </div>

        <Row gutter={[16,16]} className="trading-view-section">
          <Col span={24} className="">
            <WelcomeBanner
              tradeDetail={true} 
              showTradeDetailModalFunction={openWithdrawFormModal}
              symbol={recordDetail?.[0]?.symbol}
              ticket={deal}
            />
          </Col>

          <Col xs={24} xl={8}>
            <div className='account-card'>
              <div className="cardcontainer">
                <div className="header">
                  <h6>Trade Detail</h6>
                </div>
                <Divider/>
              </div>
              <Row>
                {detailStats.map((data,i)=>{
                  return(
                    <Col key={i} xs={24} md={12} lg={12} xl={24} className="statCard">
                      <StatsCard heading={data.heading} value={data.value}/>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </Col>
          <Col xs={24} xl={16}>
            <div className='account-card'>
              <div className="cardcontainer">
                <div className="header">
                  <h6>Trading Notes</h6>
                </div>
                <Divider/>
                </div>
                <Row>
                  <Col span={24} className="statCard">
                    <div className="trading-notes">
                    <p>Write down your own notes, game-plan and takeaway from trades. Make informed, data-driven decisions based on your past experience.</p>

                    <Form
                      form={formTradingNote}
                      name="trading_note"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      onFinish={(values) => onFinish(values, formTradingNote)}
                      layout={'vertical'}
                    >
                      <Form.Item
                        label="Setup"
                        name="setup"
                        rules={[{ 
                          required: false, 
                          message: 'Please input your setup!' 
                        }]}
                      >
                        <Input.TextArea rows={5} />
                      </Form.Item>

                      <Form.Item
                        label="Mistake"
                        name="mistake"
                        rules={[{ 
                          required: false, 
                          message: 'Please input your mistake!' 
                        }]}
                      >
                        <Input.TextArea rows={5} />
                      </Form.Item>
                      
                      <Form.Item
                        label="Other"
                        name="others"
                        rules={[{ 
                          required: false, 
                          message: 'Please input your other details!' 
                        }]}
                      >
                        <Input.TextArea rows={5} />
                      </Form.Item>

                      <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                        <Button type="primary" className="custom-primary-button h-50">
                          Save Now
                        </Button>
                      </Form.Item>
                    </Form>
                    </div>
                  
                  </Col>
                </Row>
                
            </div>
          </Col>

        </Row>
        
      </div>
      
      <ShareTradeDetailModal 
        isModalOpen={isShowShareTradeDetailModal} 
        closeModal={closeShareTradeDetailModal} 
        accountId={accountId}
        deal={deal}
        type={type}
      />
    </>
  );
}


export default TradeDetail;
