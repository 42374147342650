import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Space, Statistic, Table, Typography,Radio,Row,Col,Button,Image ,Input,Select, Divider} from "antd";
import { useEffect, useState } from "react";
import PageHeader from "../../Components/common/pageHeader";
// import PackageCard from "../../Components/Cards/packageCard";
// import WelcomeBanner from "../../Components/common/WelcomeBanner";
import WelcomeBannerV2 from "../../Components/common/WelcomeBannerV2";
import CertificateCard from "../../Components/Cards/certificateCard";
import {
  getUserCert,
  fetchAccounts
} from "../../API/index";
import { useNavigate, useParams } from "react-router-dom";


const options = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Challenges',
    value: 'challenges',
  },
  {
    label: 'Max Allocation',
    value: 'maxAllocation',
  },
  {
    label: 'Lifetime Payout',
    value: 'lifetimePayout',
  },
  {
    label: 'Competition',
    value: 'competition',
  },
  {
    label: 'Payout',
    value: 'payout',
  },
];

function Certificate() {
  const navigation = useNavigate();
  const [value4, setValue4] = useState('all');
  const [users, setUsers] = useState([]);
  const uniqueAccountName = users.map((x) => x.account_login);
  uniqueAccountName.unshift('All');
  const [selectedUser, setSelectedUser] = useState(null);
  const onChange4 = ({ target: { value } }) => {
    console.log('radio4 checked', value);
    setValue4(value);
  };

  const handleUserChange = (value) => {
    if (value == 'All') {
      setSelectedUser(null);
      navigation(`/certificate`);
    } else {
      setSelectedUser(value);
      navigation(`/certificate/${value}`);
    }
  };

  const certificateData=[
    {
      name:'Elite Trader',
      color:'#DD0744',
      status:'challenges'
    },
    {
      name:'Crown Trader',
      color:'#FF9A44',
      status:'maxAllocation'
    },
    {
      name:'Payout 1',
      color:'#DD0744',
      status:'payout'
    },
    {
      name:'Competition',
      color:'#DD0744',
      status:'competition'
    },
    {
      name:'Payout 2',
      color:'#FF9A44',
      status:'payout'
    },
    {
      name:'Lifetime Payout',
      color:'#FF9A44',
      status:'lifetimePayout'
    },
    {
      name:'Max Allocation',
      color:'#DD0744',
      status:'maxAllocation'
    },
  ]

  const [certData, setCertData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchUserid = async () => {
      try {
        const accountsData = await fetchAccounts();
        setUsers(accountsData);
        setSelectedUser(id || null);
        //setSelectedUser(optionList || null);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserid();
  }, [id]);

  useEffect(() => {
    const fetchUserCert = async () => {
      try {
        let accountLogin;
        if (id) {
          accountLogin = id;
        }
        console.log('accountLogin', accountLogin)
        const certData = await getUserCert(accountLogin);
        setCertData(certData.privileges)
      } catch (error) {
        console.error("Error fetching user cert:", error);
      }
    };

    fetchUserCert();
  }, [id]);

  // if (certData.length > 0) {
  //   const filteredCertificates = certificateData.filter(data => {
  //     return value4 === 'all' ? true : data.status.toLowerCase() === value4.toLowerCase();
  //   });
  // }
  return (
    <>
    <div className="Certificate-page">
      <div className="banner">
        <WelcomeBannerV2 
          title={'Welcome to Trader Flow Education'} 
          subheading={'Unleash your trading prowess and proudly display your mastery over charts.'}
        />
      </div>
      <div className="header">
        <div className="flex justify-between items-center flex-col mb-4">
          <div className="w-full flex gap-x-4 gap-y-4 justify-between items-center xl:gap-[20px] sm:justify-start mb-2 sm:mb-0">
            <PageHeader
              title={'Certificate'}
              //subheading={'Celebrate your accomplishments as you unlock and download certificates, highlighting your excellence and success at every milestone.'}
            />
            <Select
              value={selectedUser || 'All'}
              onChange={handleUserChange}
              options={uniqueAccountName.map((packageName) => ({
                value: packageName,
                label: packageName,
              }))}
            ></Select>
          </div>
          <div className="pt-5">
            <p>{'Celebrate your accomplishments as you unlock and download certificates, highlighting your excellence and success at every milestone.'}</p>
          </div>
          {/* <Col span={12} className="right-section"> */}
          {/* <Col xs={24} md={12} className="flex justify-start items-center md:justify-end">
            <Radio.Group
              options={options}
              onChange={onChange4}
              value={value4}
              optionType="button"
              buttonStyle="solid"
              className="customradio"
            />
          </Col> */}
        </div>
      </div>
      
      <Row gutter={16}>
        {
          certData.map((data,i)=>{
            return(
              <Col xs={24} sm={12} lg={12} xl={8} xxl={6} key={i}>
                {/* <CertificateCard name={data.name} color={data.color} background={data.cert_url}/> */}
                <CertificateCard background={data.cert_url} name={data.challengePhase.title} account={data.account_login}/>
              </Col>
            )
          })
        }
      </Row>
    </div>
    
    </>
    
  );
}


export default Certificate;
