import { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import {
    LoadingOutlined,
    InfoCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined 
} from "@ant-design/icons";
import { 
    Row,
    Col,
    Divider,
} from 'antd';
import { Button } from "Components/ui/button"; 
import { 
    getPurchaseChallengeStatus
  } from "../../../../API";

function NewChallengeStatus() {
    const { id } = useParams();

    const [challengeStatusData, setChallengeStatusData] = useState(null);
    const [purchaseChallengeStatus, setPurchaseChallengeStatus] = useState("loading"); 

    const formatAmount = (value, decimalPlaces = 2) => {

        const numericValue = typeof value === 'string' ? parseFloat(value) : value;

        // Ensure the value is a number
        if (isNaN(numericValue)) {
            return '';
        }
        
        return numericValue.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

   
    useEffect(() => {
        let fetchCount = 0; 

        const intervalId = setInterval(() => {
            fetchCount  = fetchCount + 1;
        
            fetchPurchaseChallengeStatus(fetchCount); 
         
            if (fetchCount >= 5) {
                clearInterval(intervalId);
                setPurchaseChallengeStatus("processing");
                fetchCount = 0;
            }
        }, 5000);

        const fetchPurchaseChallengeStatus = async (count) => {
            try {
                // Fetch data from API
                const result = await getPurchaseChallengeStatus(id);
                setChallengeStatusData(result);

                let transactionStatus = result?.transaction?.status ?? 0;
                
                if (transactionStatus == 0) {
                    setPurchaseChallengeStatus("processing"); 
                } 
                if (transactionStatus == 1) {
                    setPurchaseChallengeStatus("success"); 
                    // Stop interval if data status is successfully fetched
                    clearInterval(intervalId);
                } 
                if (transactionStatus == 2) {
                    setPurchaseChallengeStatus("fail"); 
                    // Stop interval if data status is successfully fetched
                    clearInterval(intervalId);
                }
                
            } catch (error) {
                console.error('Error fetching Purchase Challenge Status:', error);
            }
        };
        
        fetchPurchaseChallengeStatus();
        
        // Clean up interval on component unmount
        return () => clearInterval(intervalId);

    }, [id]);

    useEffect(() => {
        if (challengeStatusData) {
            let tempAmount = challengeStatusData?.transaction?.amount ?? 0;
            // eslint-disable-next-line no-undef
            window.fbq('track', 'Purchase', {
                txn_id: challengeStatusData?.transaction?.txn_id ?? '',
                status: challengeStatusData?.transaction?.status ?? '',
                description: challengeStatusData?.transaction?.description ?? '',
                value: formatAmount(tempAmount),
                currency: challengeStatusData?.transaction?.currency_unit ?? "",
            });
            // eslint-disable-next-line no-undef
            window.fbq('track', 'Purchase');
        }
    },[purchaseChallengeStatus, challengeStatusData])

    return (
        <>
            <div className="purchase-challenge-page px-[40px] py-[30px]">
                <div className='text-xl font-semibold pb-3'>Purchase Challenge</div>
                <Row gutter={16} >
                    <Col className="gutter-row" span={24}>
                        <div className="bg-white rounded-xl">
                            <div className="pt-[20px] pl-[20px] text-[#1D1E25] font-semibold text-lg">
                                <h6>Transaction Status</h6>
                            </div>
                            <Divider/>
                            <form id="formChallengeStatusInfo">
                                <Row gutter={[16,16]} className='pt-6 pb-12'>
                                    <Col className="gutter-row" span={24}>
                                        {/* {purchaseChallengeStatus === 'loading' && (
                                            <div className='flex justify-center'>
                                                <LoadingOutlined className='text-[#DD0744] text-3xl'/>
                                            </div>
                                        )} */}

                                        <div className='flex justify-center'>
                                            {(() => {
                                                if (purchaseChallengeStatus === 'processing') {
                                                    return <InfoCircleOutlined className='text-gray-400 text-6xl'/>
                                                } else if (purchaseChallengeStatus === 'success') {
                                                    return <CheckCircleOutlined className='text-green-400 text-6xl' />;
                                                } else if (purchaseChallengeStatus === 'fail') {
                                                    return <CloseCircleOutlined className='text-[#DD0744] text-6xl'/>;
                                                } else {
                                                    return <LoadingOutlined className='text-[#DD0744] text-6xl' />;
                                                }
                                            })()}
                                        </div>
                                        <div className='mt-3 text-center'>
                                            {(() => {
                                                if (purchaseChallengeStatus === 'processing') {
                                                    return "Your purchase challenge transaction still in processing...";
                                                } else if (purchaseChallengeStatus === 'success') {
                                                    return "Purchase challenge transaction successfully.";
                                                } else if (purchaseChallengeStatus === 'fail') {
                                                    return "Purchase challenge transaction Failed.";
                                                } else {
                                                    return "";
                                                }
                                            })()}
                                        </div>
                                        <div className='challenge-status-data'>
                                            <Row gutter={16} className="mb-3 mt-3">
                                                <Col xs={24} md={12} lg={12} xl={8} className="m-auto">
                                                    <div className="bg-black rounded-lg p-4 text-white">
                                                        <div className="grid gap-2 grid-cols-2 ">
                                                            <div className="text-[rgba(255,255,255,.7)]">
                                                                Transaction ID
                                                            </div>
                                                            <div className="text-right">
                                                                {challengeStatusData?.transaction?.txn_id ?? "-"}
                                                            </div>
                                                        </div>
                                                        <hr className="my-4 border-[rgba(255,255,255,.7)]" />
                                                        <div className="grid gap-2 grid-cols-2 ">
                                                            <div className="text-[rgba(255,255,255,.7)]">
                                                                Challenge Type
                                                            </div>
                                                            <div className="text-right">
                                                                {challengeStatusData?.transaction?.description ?? "-"}
                                                            </div>
                                                            <div className="text-[rgba(255,255,255,.7)]">
                                                                Account Balance
                                                            </div>
                                                            <div className="text-right">
                                                                {challengeStatusData?.transaction?.debit_amount ? formatAmount(challengeStatusData?.transaction?.debit_amount, 2) : "0.00"}
                                                            </div>
                                                            <div className="text-[rgba(255,255,255,.7)]">
                                                                Platform
                                                            </div>
                                                            <div className="text-right">
                                                                MT5
                                                            </div>
                                                        </div>
                                                        <hr className="my-4 border-[rgba(255,255,255,.7)]" />
                                                        <div className="grid gap-2 grid-cols-2 ">
                                                            <div className="text-[rgba(255,255,255,.7)]">
                                                                Price
                                                            </div>
                                                            <div className="text-right">
                                                                <span id="spanPricePay" className='text-white font-bold text-2xl md:text-4xl'>
                                                                    ${challengeStatusData?.transaction?.amount ? formatAmount(challengeStatusData?.transaction?.amount, 2) : "0.00"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>


                                        {/* {purchaseChallengeStatus === 'processing' && (
                                            <div className='flex justify-center items-center flex-col'>
                                                <InfoCircleOutlined className='text-gray-400 text-6xl'/>
                                                <div className='mt-3 text-center'>Your purchase challenge transaction still in processing...</div>
                                                <div>Transaction ID: <span className='font-semibold'>{challengeStatusData?.transaction?.txn_id ?? ""}</span></div>
                                            </div>
                                        )} */}
                                        
                                        {/* {purchaseChallengeStatus === 'success' && (
                                            <>
                                                <div className='flex justify-center items-center flex-col'>
                                                    <CheckCircleOutlined className='text-green-400 text-6xl'/>
                                                    <div className='mt-3 text-center'>Purchase challenge transaction successfully.</div>
                                                </div>
                                                <Row gutter={16} className="mb-3 mt-3">
                                                    <Col xs={24} md={12} lg={12} xl={8} className="m-auto">
                                                        <div className="bg-black rounded-lg p-4 text-white">
                                                            <div className="grid gap-2 grid-cols-2 ">
                                                                <div className="text-[rgba(255,255,255,.7)]">
                                                                    Transaction ID
                                                                </div>
                                                                <div className="text-right">
                                                                    {challengeStatusData?.transaction?.txn_id ?? ""}
                                                                </div>
                                                            </div>
                                                            <hr className="my-4 border-[rgba(255,255,255,.7)]" />
                                                            <div className="grid gap-2 grid-cols-2 ">
                                                                <div className="text-[rgba(255,255,255,.7)]">
                                                                    Challenge Type
                                                                </div>
                                                                <div className="text-right">
                                                                    {challengeStatusData?.transaction?.description ?? ""}
                                                                </div>
                                                                <div className="text-[rgba(255,255,255,.7)]">
                                                                    Account Balance
                                                                </div>
                                                                <div className="text-right">
                                                                    {challengeStatusData?.transaction?.debit_amount ? formatAmount(challengeStatusData?.transaction?.debit_amount, 2) : ""}
                                                                </div>
                                                                <div className="text-[rgba(255,255,255,.7)]">
                                                                    Platform
                                                                </div>
                                                                <div className="text-right">
                                                                    MT5
                                                                </div>
                                                            </div>
                                                            <hr className="my-4 border-[rgba(255,255,255,.7)]" />
                                                            <div className="grid gap-2 grid-cols-2 ">
                                                                <div className="text-[rgba(255,255,255,.7)]">
                                                                    Price
                                                                </div>
                                                                <div className="text-right">
                                                                    <span id="spanPricePay" className='text-white font-bold text-2xl md:text-4xl'>
                                                                        {challengeStatusData?.transaction?.amount ? "$"+formatAmount(challengeStatusData?.transaction?.amount, 2) : ""}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        )} */}

                                        {/* {purchaseChallengeStatus === 'fail' && (
                                            <>
                                                <div className='flex justify-center items-center flex-col'>
                                                    <CloseCircleOutlined className='text-[#DD0744] text-6xl'/>
                                                    <div className='mt-3 text-center'>Purchase challenge transaction Failed.</div>
                                                </div>
                                                <Row gutter={16} className="mb-3 mt-3">
                                                    <Col xs={24} md={12} lg={12} xl={8} className="m-auto">
                                                        <div className="bg-black rounded-lg p-4 text-white">
                                                            <div className="grid gap-2 grid-cols-2 ">
                                                                <div className="text-[rgba(255,255,255,.7)]">
                                                                    Transaction ID
                                                                </div>
                                                                <div className="text-right">
                                                                    {challengeStatusData?.transaction?.txn_id ?? ""}
                                                                </div>
                                                            </div>
                                                            <hr className="my-4 border-[rgba(255,255,255,.7)]" />
                                                            <div className="grid gap-2 grid-cols-2 ">
                                                                <div className="text-[rgba(255,255,255,.7)]">
                                                                    Challenge Type
                                                                </div>
                                                                <div className="text-right">
                                                                    {challengeStatusData?.transaction?.description ?? ""}
                                                                </div>
                                                                <div className="text-[rgba(255,255,255,.7)]">
                                                                    Account Balance
                                                                </div>
                                                                <div className="text-right">
                                                                    {challengeStatusData?.transaction?.debit_amount ? formatAmount(challengeStatusData?.transaction?.debit_amount, 2) : ""}
                                                                </div>
                                                                <div className="text-[rgba(255,255,255,.7)]">
                                                                    Platform
                                                                </div>
                                                                <div className="text-right">
                                                                    MT5
                                                                </div>
                                                            </div>
                                                            <hr className="my-4 border-[rgba(255,255,255,.7)]" />
                                                            <div className="grid gap-2 grid-cols-2 ">
                                                                <div className="text-[rgba(255,255,255,.7)]">
                                                                    Price
                                                                </div>
                                                                <div className="text-right">
                                                                    <span id="spanPricePay" className='text-white font-bold text-4xl'>{challengeStatusData?.transaction?.amount ? "$"+formatAmount(challengeStatusData?.transaction?.amount, 2) : ""}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        )} */}

                                        
                                        <div className='flex justify-center items-center mt-4'>
                                            <Link to="/matrix">
                                                <Button 
                                                    className="custom-primary-button px-10" 
                                                >
                                                    <span>Back To Matrix Page</span>
                                                </Button>
                                            </Link>
                                        </div>
                                       
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default NewChallengeStatus;
