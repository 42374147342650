import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import axios from 'axios'
import { Card, Space, Statistic, Table, Typography,Radio,Row,Col,Button,Image ,Input,Select, Divider} from "antd";
import { useEffect, useState } from "react";
import PageHeader from "../../Components/common/pageHeader";
import { Tabs } from 'antd';
import GenericTable from "../../Components/Tables";



function Billing() {
  const [transactions, settransactions] = useState([]);
  useEffect(() => {
    // Function to fetch data from API
    const fetchData = async () => {
      try {
        const storedToken = localStorage.getItem('token');

        const response = await axios.get('https://tfapi-dev.tradersflow.co/api/v1/admin/transactions', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });

        const data = response.data.transactions.data;
        settransactions(data); 
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData(); // Invoke the fetchData function
  }, []); //
  const tableColumns = [
    {
      title: 'SN',
      dataIndex: 'sn',
      key: 'sn',
    },
    {
      title: 'Account Size',
      dataIndex: 'accountSize',
      key: 'accountSize',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return <div className={`statusBadge ${status === 'success' ? 'open' : 'close'}`}><span></span>{status}</div>;
      },
    }, 
    {
      title: 'Invoice',
      dataIndex: 'invoice',
      key: 'invoice',
      render: () => {
        return <Button type="primary" className="custom-primary-button"> OPEN</Button>;
      },
    }, 
  ];
  const tableData = transactions.map((withdrawal, index) => ({
    key: index.toString(),
    sn: index + 1,
    accountSize: "1000",
    date: withdrawal.created_at.split('T')[0],
    transactionId: ` ${withdrawal.txn_id? withdrawal.txn_id : ' ID  Not Found'}`,
    transactionType: ` ${withdrawal.description ? withdrawal.description.slice(0, 28) : ''}`,
    amount: withdrawal.amount ? `$ ${parseFloat(withdrawal.amount).toFixed(2)}` : '$ 0.00',
    discount: 0,
    totalAmount: withdrawal.debit_amount ? `$ ${parseFloat(withdrawal.debit_amount).toFixed(2)}` : '$ 0.00',
    status: withdrawal.status === 1 ? 'success' : 'pending',
    invoice: withdrawal.invoice || 'open',
  }));
  const tableHeader = 'Trading History';
  return (
    <>
    <div className="Matrix-page">
      <div className="header">
        <div className="left-section">
        <PageHeader
        title={'Billing'}
         />
         
        </div>
        <Button type="primary" className="custom-primary-button br-15 h-50">Start New Challenge</Button>
      </div>
  
      <Row gutter={16}>
        <Col span={24}>
          <div className="table-container">
            <PageHeader
            title={'Payment History'}
            />
              <GenericTable header={tableHeader} columns={tableColumns} data={tableData} />
          </div>
        </Col>
      </Row>
    </div>
    </>
  );
}


export default Billing;
