// Header.js

import React from 'react';
import { Row,Col,Image,Dropdown} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles
import { useState } from "react";
import PDFModal from '../pdfModal';


const CertificateCard = ({name,color,locked,background,account}) => {
  const [pdfPath, setPdfPath] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);


  const handleSetPdfPath = (newPath) => {
    setPdfPath(newPath);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const corsProxy = 'https://cors-anywhere.herokuapp.com/';
  const proxyUrl = `${corsProxy}${background}`;


  return (
    <div style={{ position: 'relative' }}>
      {/* <div className='certificate-card' onClick={() => handleSetPdfPath(proxyUrl)}>
        <div className='certificate-internal'>
          <div className='box'>
            <div className='icon' style={{background: color , border:'1px solid red' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                  <path d="M26.5625 11.3334V14.3084C25.9392 14.2234 25.2308 14.1809 24.4375 14.1667V11.3334C24.4375 6.87087 23.1767 3.89587 17 3.89587C10.8233 3.89587 9.5625 6.87087 9.5625 11.3334V14.1667C8.76917 14.1809 8.06083 14.2234 7.4375 14.3084V11.3334C7.4375 7.22504 8.42917 1.77087 17 1.77087C25.5708 1.77087 26.5625 7.22504 26.5625 11.3334Z" fill="white"/>
                  <path d="M26.5627 14.3083C25.9393 14.2233 25.231 14.1808 24.4377 14.1666H9.56266C8.76933 14.1808 8.061 14.2233 7.43766 14.3083C3.82516 14.7475 2.8335 16.5183 2.8335 21.25V24.0833C2.8335 29.75 4.25016 31.1666 9.91683 31.1666H24.0835C29.7502 31.1666 31.1668 29.75 31.1668 24.0833V21.25C31.1668 16.5183 30.1752 14.7475 26.5627 14.3083ZM12.3393 23.6725C12.0702 23.9275 11.7018 24.0833 11.3335 24.0833C11.1493 24.0833 10.9652 24.0408 10.7952 23.97C10.611 23.8991 10.4693 23.8 10.3277 23.6725C10.0727 23.4033 9.91683 23.035 9.91683 22.6666C9.91683 22.4825 9.95933 22.2983 10.0302 22.1283C10.101 21.9583 10.2002 21.8025 10.3277 21.6608C10.4693 21.5333 10.611 21.4341 10.7952 21.3633C11.3193 21.1366 11.9427 21.2641 12.3393 21.6608C12.4668 21.8025 12.566 21.9583 12.6368 22.1283C12.7077 22.2983 12.7502 22.4825 12.7502 22.6666C12.7502 23.035 12.5943 23.4033 12.3393 23.6725ZM18.3035 23.205C18.2327 23.375 18.1335 23.5308 18.006 23.6725C17.7368 23.9275 17.3685 24.0833 17.0002 24.0833C16.6177 24.0833 16.2635 23.9275 15.9943 23.6725C15.8668 23.5308 15.7677 23.375 15.6968 23.205C15.626 23.035 15.5835 22.8508 15.5835 22.6666C15.5835 22.2841 15.7393 21.93 15.9943 21.6608C16.5185 21.1366 17.4677 21.1366 18.006 21.6608C18.261 21.93 18.4168 22.2841 18.4168 22.6666C18.4168 22.8508 18.3743 23.035 18.3035 23.205ZM23.6727 23.6725C23.4035 23.9275 23.0352 24.0833 22.6668 24.0833C22.2985 24.0833 21.9302 23.9275 21.661 23.6725C21.406 23.4033 21.2502 23.0491 21.2502 22.6666C21.2502 22.2841 21.406 21.93 21.661 21.6608C22.1993 21.1366 23.1485 21.1366 23.6727 21.6608C23.7293 21.7316 23.786 21.8025 23.8427 21.8875C23.8993 21.9583 23.9418 22.0433 23.9702 22.1283C24.0127 22.2133 24.041 22.2983 24.0552 22.3833C24.0693 22.4825 24.0835 22.5816 24.0835 22.6666C24.0835 23.035 23.9277 23.4033 23.6727 23.6725Z" fill="white"/>
                </svg>
            </div>
            <h1>{name}</h1>
          </div>

        </div>

      </div> */}
      {/* <embed src={background} type="application/pdf"  height="300px" width="100%" class="responsive" full-frame="" /> */}
      <iframe src={background}
          style={{ width: '100%', height: '300px'}} frameBorder="0" >
      </iframe>
      <div 
        onClick={() => window.open(background, '_blank')}
        style={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          width: '100%', 
          height: '100%', 
          cursor: 'pointer', 
          backgroundColor: 'transparent' 
        }} 
      />
      <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1 style={{ fontWeight: 'bold' }}>{account}</h1>
        <h1 style={{ fontWeight: 'bold' }}>{name}</h1>
      </div>
      
      {pdfPath && (
      <PDFModal
        pdfPath={pdfPath}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
      />
    )}      
    </div>        
  );
};

export default CertificateCard;
