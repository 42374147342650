import React from 'react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation

const GenericTable = ({ header, columns, data, redirectPath }) => {
    const navigate = useNavigate();

  const handleRowClick = (record) => {
    // Redirect to another page when a row is clicked
    navigate(`${redirectPath}/${record.id}`); // Update this with your actual data property or logic
  };

  // Add the row click handler to the table
  const rowProps = {
    onClick: (event, record) => {
      handleRowClick(record);
    },
  };

  return (
    <div style={{ overflowY: 'auto' }}>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        // onRow={(record, rowIndex) => ({
        //   ...rowProps,
        // })}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default GenericTable;
