// Countdown.jsx

import React, { useEffect, useState } from 'react';
import './index.scss';

const CountdownBox = ({ value, animate,label }) => {
  return <div className={`countdown-box ${animate ? 'flip' : ''}`}>
    <div className='value'>{value}</div>
    <div className='label'>{label}</div>
  </div>;
};
const Icon =()=>{
  return(
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="32" viewBox="0 0 9 32" fill="none">
<g opacity="0.5">
<circle cx="4.36364" cy="4.36364" r="4.36364" fill="white"/>
<circle cx="4.36364" cy="27.6366" r="4.36364" fill="white"/>
</g>
</svg>
)
}

const CountdownCompetition = ({ countdownTime ,gmt}) => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [prevSeconds, setPrevSeconds] = useState(0);
  const [prevMinutes, setPrevMinutes] = useState(0);

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const eventTime = new Date(countdownTime + ' GMT' + gmt).getTime();
      console.log(eventTime)
      const currentTime = new Date().getTime();
      const timeDifference = eventTime - currentTime;

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setCountdown({ days, hours, minutes, seconds });

        // Trigger animation only when seconds or minutes change
        if (seconds !== prevSeconds) {
          setPrevSeconds(seconds);
        }

        if (minutes !== prevMinutes) {
          setPrevMinutes(minutes);
        }
      } else {
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countdownTime, gmt, prevSeconds, prevMinutes]);

  console.log("tt",countdown.hours)


  return (
    <div className="countdownCardCompetition">
      
      <div className="countdown-container">
        <CountdownBox value={countdown.days} label="Days" animate={countdown.days > 0} />
        <CountdownBox value={countdown.hours} label="Hours" animate={countdown.hours > 0} />
        <CountdownBox value={countdown.minutes} label="Minutes" animate={countdown.minutes !== prevMinutes} />
        <CountdownBox value={countdown.seconds} label="Seconds" animate={countdown.seconds !== prevSeconds} />
      </div>
    </div>
  );
};
export default CountdownCompetition;
