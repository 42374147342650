// Header.js

import React, { useState } from 'react';
import { Card, Space, Statistic, Table, Typography,Radio,Row,Col,Button,Image ,Input,Select, Divider, Checkbox  } from "antd";
import { Form } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
const { Dragger } = Upload;


const { Option } = Select;
const options = [
  {
    label: 'Bank Transfer',
    value: 'banktransfer',
  },
  {
    label: 'Perfect Money',
    value: 'perfectMoney',
  },
  
];

const props = {
  name: 'file',
  multiple: true,
  action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};
const OthersPaymentForm = ({onFinish, paymentMethod, onPaymentMethodChange,}) => {
  const [value4, setValue4] = useState('banktransfer');


  const onChange4 = ({ target: { value } }) => {
    console.log('radio4 checked', value);
    onPaymentMethodChange(value); // Pass the value to the parent component
    setValue4(value);
  };

 
  

    return(
        <>
        <Row gutter={16}>
          <Col span={24} className='d-flex'>
          <Radio.Group
            options={options}
            onChange={onChange4}
            value={paymentMethod}
            optionType="button"
            buttonStyle="solid"
            className="customradio mx-auto "
          />

          </Col>
        </Row>
         <Form
              name="card_payment_form"
              onFinish={onFinish}
              initialValues={{ remember: true }}
              layout="vertical"
            >
            
              <Row gutter={16}>
              
                <Col span={24}>
                  <Form.Item
                    label={<div className='form-label-custom'>
                      <h5>Upload The Proof of Your Payment *</h5>
                      <p>Ex. Bank Transfer Receipt, BTC Wallet Screenshot Etc</p>
                      </div>}
                    name="Currency"
                    rules={[{ required: false, message: 'Your Cryptocurrency ' }]}
                  >
                     <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="60" height="60" rx="15" fill="url(#paint0_linear_431_7289)"/>
                      <path d="M42.7076 24.3337H38.5426C36.7434 24.3337 35.6667 23.257 35.6667 21.4578V17.2928C35.6667 15.4937 36.7434 14.417 38.5426 14.417H42.7076C44.5067 14.417 45.5834 15.4937 45.5834 17.2928V21.4578C45.5834 23.257 44.5067 24.3337 42.7076 24.3337ZM43.0192 19.6445C42.8492 19.4745 42.6226 19.3895 42.3959 19.3895C42.1692 19.3895 41.9426 19.4745 41.7726 19.6445L41.5176 19.8995V16.7262C41.5176 16.2303 41.1209 15.8337 40.6251 15.8337C40.1292 15.8337 39.7326 16.2303 39.7326 16.7262V19.8995L39.4776 19.6445C39.1376 19.3045 38.5709 19.3045 38.2309 19.6445C37.8909 19.9845 37.8909 20.5512 38.2309 20.8912L40.0017 22.662C40.0726 22.7328 40.1717 22.7895 40.2709 22.832C40.2992 22.8462 40.3276 22.8462 40.3559 22.8603C40.4267 22.8887 40.4976 22.9028 40.5826 22.9028C40.6109 22.9028 40.6392 22.9028 40.6676 22.9028C40.7667 22.9028 40.8517 22.8887 40.9509 22.8462C40.9651 22.8462 40.9651 22.8462 40.9792 22.8462C41.0784 22.8037 41.1634 22.747 41.2342 22.6762C41.2484 22.662 41.2484 22.662 41.2626 22.662L43.0334 20.8912C43.3734 20.5512 43.3734 19.9845 43.0192 19.6445Z" fill="white"/>
                      <path d="M25.7501 27.7052C27.6122 27.7052 29.1218 26.1957 29.1218 24.3336C29.1218 22.4715 27.6122 20.9619 25.7501 20.9619C23.888 20.9619 22.3784 22.4715 22.3784 24.3336C22.3784 26.1957 23.888 27.7052 25.7501 27.7052Z" fill="white"/>
                      <path d="M42.7074 24.333H42.0416V30.8638L41.8574 30.708C40.7524 29.7588 38.9674 29.7588 37.8624 30.708L31.9691 35.7655C30.8641 36.7147 29.0791 36.7147 27.9741 35.7655L27.4924 35.3688C26.4866 34.4905 24.8858 34.4055 23.7524 35.1705L18.4541 38.7263C18.1424 37.933 17.9583 37.0122 17.9583 35.9355V24.0638C17.9583 20.0688 20.0691 17.958 24.0641 17.958H35.6666V17.2922C35.6666 16.7255 35.7658 16.2438 35.9924 15.833H24.0641C18.9074 15.833 15.8333 18.9072 15.8333 24.0638V35.9355C15.8333 37.4797 16.1024 38.8255 16.6266 39.9588C17.8449 42.6505 20.4516 44.1663 24.0641 44.1663H35.9358C41.0924 44.1663 44.1666 41.0922 44.1666 35.9355V24.0072C43.7558 24.2338 43.2741 24.333 42.7074 24.333Z" fill="white"/>
                      <defs>
                      <linearGradient id="paint0_linear_431_7289" x1="-0.00197867" y1="30.0388" x2="86.9777" y2="31.2787" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#DD0744"/>
                      <stop offset="0.9996" stop-color="#FF9A44"/>
                      </linearGradient>
                      </defs>
                      </svg>
                    </p>
                    <p className="ant-upload-text">Drop your image or Browse</p>
                    <p className="ant-upload-hint">
                    PNG. JPG
                    </p>
                    </Dragger>
                  </Form.Item>
                </Col>
                  {value4 === 'perfectMoney' && (
                     <Col span={24}>
                      <Form.Item
                          label={<div className='form-label-custom'>
                          <h5>Transaction Id/ Hash Id/ Batch Id</h5>
                          </div>}
                          name="network"
                          rules={[{ required: false, message: 'Your Cryptocurrency ' }]}
                        >
                            <Input  className='input-text h-50' placeholder='Transaction Id/ Hash Id/ Batch Id' />
                        </Form.Item>
                      </Col>
                  )}
                <Col span={24}>
                  <Form.Item
                    label={<div className='form-label-custom'>
                    <h5>Referred by (optional)</h5>
                    <p>Kindly submit the email of the person who referred you.</p>
                    </div>}
                    name="network"
                    rules={[{ required: false, message: 'Your Cryptocurrency ' }]}
                  >
                      <Input  className='input-text h-50' placeholder='Enter Email of The Person Who Referred You' />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="termsAndConditions" valuePropName="checked">
                  <Checkbox >
                  I declare that I have read and agree with<span className="color-span"> Terms & Conditions,
                    Disclosure Statement</span> & <span className="color-span"> Privacy Policy</span>
                  </Checkbox>
                </Form.Item>
              </Col>
              </Row>

              <Form.Item>
                <Button type="primary" className="custom-primary-button h-50 d-flex justify-content-between align-items-center " htmlType="submit">
                  Confirm Payment 
                </Button>
              </Form.Item>
          </Form>
        </>

    )
   
 
};

export default OthersPaymentForm;
