import {
  Card,
  Col,
  Divider,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Statistic,
  Table,
  Typography,
} from "antd";
import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Button } from "Components/ui/button";
import { Link } from "react-router-dom";
import PackageCard from "../../Components/Cards/packageCard";
import PageHeader from "../../Components/common/pageHeader";
import axios from "axios";
import { fetchAccounts } from "../../API/index";

const options = [
  {
    label: "Active Account",
    value: "active",
  },
  // {
  //   label: "Inactive Account",
  //   value: "inactive",
  // },
  {
    label: "Breached Account",
    value: "breached",
  },
];

function Dashboard() {
  const [searchQuery, setSearchQuery] = useState("");
  const [value2, setValue2] = useState("Select Package");
  const [value4, setValue4] = useState("active");

  const onChange4 = ({ target: { value } }) => {
    setValue4(value);
  };

  const [accounts, setAccounts] = useState([]);

  const uniquePackageNames = [
    ...new Set(accounts.map((account) => account.plan.name)),
  ];

  const handleSearch = async (query) => {
    const accountsData = await fetchAccounts(query);
    setAccounts(accountsData);
  };

  const onSearchInputChange = ({ target: { value } }) => {
    setSearchQuery(value);
    handleSearch(value);
    console.log("searhc valeu", value);
  };
  useEffect(() => {
    const fetchData = async () => {
      const accountsData = await fetchAccounts();
      setAccounts(accountsData);
    };

    fetchData();
    console.log("teh user data accoutn", accounts);
  }, []);

  let matchesLoggedInUser;

  return (
    <>
      <div className="Dashboard-page">
        {/* <div className="header">
        <PageHeader
          title={'Welcome to'}
          spanTitle={' Trader Flow'}
          subheading={'Complete The Challenge Phases, Harness Your Trading Skills, And Get Trader Flow Account!'}
        />
        <Radio.Group
          options={options}
          onChange={onChange4}
          value={value4}
          optionType="button"
          buttonStyle="solid"
          className="customradio"
        />
      </div> */}
        <div className="header">
          <Row gutter={16}>
            <Col xs={24} md={12} className="mb-4 md:mb-0">
              <PageHeader
                title={"Welcome to"}
                spanTitle={" Trader Flow"}
                subheading={
                  "Complete The Challenge Phases, Harness Your Trading Skills, And Get Trader Flow Account!"
                }
              />
            </Col>
            <Col
              xs={24}
              md={12}
              className="flex justify-start items-center md:justify-end"
            >
              <Radio.Group
                options={options}
                onChange={onChange4}
                value={value4}
                optionType="button"
                buttonStyle="solid"
                className="customradio"
              />
            </Col>
          </Row>
        </div>

        <Row gutter={16}>
          <Col className="gutter-row" xs={24} xl={18}>
            <div className="account  w-100">
              <div className="accountheader">
                <Row gutter={16} className="w-100">
                  <Col xs={24} md={8} className="mb-3 md:mb-0">
                    <h4>Accounts</h4>
                  </Col>
                  <Col xs={24} md={16}>
                    {/* <div className="accountHeader-left"> */}
                    <div className="flex gap-3 flex-col sm:flex-row justify-start md:justify-end items-center">
                      <Input
                        className="input-text py-[9px] px-[5x] lg:max-w-[300px]"
                        placeholder="Search"
                        prefix={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.0273 7.22228C10.2687 7.22228 7.22179 10.2692 7.22179 14.0278C7.22179 17.7864 10.2687 20.8334 14.0273 20.8334C17.7859 20.8334 20.8329 17.7864 20.8329 14.0278C20.8329 10.2692 17.7859 7.22228 14.0273 7.22228ZM5.27734 14.0278C5.27734 9.19534 9.19485 5.27783 14.0273 5.27783C18.8598 5.27783 22.7773 9.19534 22.7773 14.0278C22.7773 18.8603 18.8598 22.7778 14.0273 22.7778C9.19485 22.7778 5.27734 18.8603 5.27734 14.0278Z"
                              fill="#828282"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M20.7296 20.7296C21.1093 20.3499 21.7248 20.3499 22.1045 20.7296L24.4379 23.0629C24.8175 23.4426 24.8175 24.0582 24.4379 24.4379C24.0582 24.8175 23.4426 24.8175 23.0629 24.4379L20.7296 22.1045C20.3499 21.7248 20.3499 21.1093 20.7296 20.7296Z"
                              fill="#828282"
                            />
                          </svg>
                        }
                        value={searchQuery}
                        onChange={onSearchInputChange}
                      />
                      {uniquePackageNames.length > 0 ? (
                        <Select
                          value={value2}
                          className="w-full md:w-auto"
                          onChange={setValue2}
                          options={uniquePackageNames.map((packageName) => ({
                            value: packageName,
                            label: packageName,
                          }))}
                        />
                      ) : (
                        <p>No packages found.</p>
                      )}
                      {/* <Button type="primary" className="custom-primary-button"> Create New Account</Button> */}
                    </div>
                  </Col>
                </Row>
              </div>
              <Divider />
              <div className="package-card-container">
                {accounts.length > 0 ? (
                  accounts
                    .filter((account) => {
                      const matchesSearchQuery =
                        account.plan.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        (account.account_login &&
                          account.account_login
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()));
                      const matchesSelectedPackage =
                        value2 === "Select Package" ||
                        account.plan.name === value2;
                      // const matchesStatusFilter =
                      //   value4 === "active"
                      //     ? account.active && !account.user.blocked
                      //     : value4 === "inactive"
                      //     ? !account.active && !account.user.blocked
                      //     : value4 === "breached"
                      //     ? account.user.blocked
                      //     : true;
                      const matchesStatusFilter =
                        value4 === "active"
                          ? account.status === 1 || account.status === 3
                          : value4 === "inactive"
                          ? !account.active && !account.user.blocked
                          : value4 === "breached"
                          ? account.status === 2 || account.status === 4
                          : true;
                      matchesLoggedInUser =
                        account.user.id && account.plan.active === true;
                      return (
                        matchesSearchQuery &&
                        matchesSelectedPackage &&
                        matchesStatusFilter &&
                        matchesLoggedInUser
                      );
                    })
                    .map((account, index) => (
                      <PackageCard
                        key={account.id}
                        name={account.plan.name}
                        image={"images/tlogo.png"}
                        price={`$${account.daily_equity}`}
                        id={account?.account_login}
                        phase={"Foundation"}
                        endDate={
                          account.challenge_end_date
                            ? new Date(
                                account.challenge_end_date
                              ).toLocaleDateString()
                            : ""
                        }
                        //server={account.plan.group}
                        server={account.account_type}
                        active={index === 0}
                      />
                    ))
                ) : (
                  <Skeleton />
                )}
                {accounts.length > 0 &&
                  accounts.filter((account) => account.user.blocked).length ===
                    0 &&
                  value4 === "breached" && <p></p>}
              </div>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} xl={6}>
            <div className="banner">
              <div className="logobanner">
                <Image src="images/darkLogo.png" alt="Dark logo" />
              </div>
              <div className="challangeText">
                <h2>Challenge</h2>

                <div className="box">
                  <p>Trade up to $200,000 with TradersFlow account</p>
                </div>
                <div className="list">
                  <h5>You Will Get</h5>
                  <ul>
                    <li>
                      <div className="check-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <path
                            d="M9.99984 2.59668C5.40817 2.59668 1.6665 6.33835 1.6665 10.93C1.6665 15.5217 5.40817 19.2633 9.99984 19.2633C14.5915 19.2633 18.3332 15.5217 18.3332 10.93C18.3332 6.33835 14.5915 2.59668 9.99984 2.59668ZM13.9832 9.01335L9.25817 13.7383C9.1415 13.855 8.98317 13.9217 8.8165 13.9217C8.64984 13.9217 8.4915 13.855 8.37484 13.7383L6.0165 11.38C5.77484 11.1383 5.77484 10.7383 6.0165 10.4967C6.25817 10.255 6.65817 10.255 6.89984 10.4967L8.8165 12.4133L13.0998 8.13001C13.3415 7.88835 13.7415 7.88835 13.9832 8.13001C14.2248 8.37168 14.2248 8.76335 13.9832 9.01335Z"
                            fill="#DD0744"
                          />
                        </svg>
                      </div>
                      1 Step Challenge With No Limit and 2 Steps Challenge With
                      Minimum 10 Days To Pass
                    </li>
                    <li>
                      <div className="check-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <path
                            d="M9.99984 2.59668C5.40817 2.59668 1.6665 6.33835 1.6665 10.93C1.6665 15.5217 5.40817 19.2633 9.99984 19.2633C14.5915 19.2633 18.3332 15.5217 18.3332 10.93C18.3332 6.33835 14.5915 2.59668 9.99984 2.59668ZM13.9832 9.01335L9.25817 13.7383C9.1415 13.855 8.98317 13.9217 8.8165 13.9217C8.64984 13.9217 8.4915 13.855 8.37484 13.7383L6.0165 11.38C5.77484 11.1383 5.77484 10.7383 6.0165 10.4967C6.25817 10.255 6.65817 10.255 6.89984 10.4967L8.8165 12.4133L13.0998 8.13001C13.3415 7.88835 13.7415 7.88835 13.9832 8.13001C14.2248 8.37168 14.2248 8.76335 13.9832 9.01335Z"
                            fill="#DD0744"
                          />
                        </svg>
                      </div>
                      Up to $200,000 per account
                    </li>
                    <li>
                      <div className="check-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <path
                            d="M9.99984 2.59668C5.40817 2.59668 1.6665 6.33835 1.6665 10.93C1.6665 15.5217 5.40817 19.2633 9.99984 19.2633C14.5915 19.2633 18.3332 15.5217 18.3332 10.93C18.3332 6.33835 14.5915 2.59668 9.99984 2.59668ZM13.9832 9.01335L9.25817 13.7383C9.1415 13.855 8.98317 13.9217 8.8165 13.9217C8.64984 13.9217 8.4915 13.855 8.37484 13.7383L6.0165 11.38C5.77484 11.1383 5.77484 10.7383 6.0165 10.4967C6.25817 10.255 6.65817 10.255 6.89984 10.4967L8.8165 12.4133L13.0998 8.13001C13.3415 7.88835 13.7415 7.88835 13.9832 8.13001C14.2248 8.37168 14.2248 8.76335 13.9832 9.01335Z"
                            fill="#DD0744"
                          />
                        </svg>
                      </div>
                      Up to 90% profit split
                    </li>
                  </ul>
                  <p>
                    Successfully complete the straightforward evaluation process
                    to qualify for a funded TradersFlow account!
                  </p>

                  {/* <Button type="primary" className="custom-primary-button w-100 h-50">
                <Link to={'/new-challenge'} style={{paddingRight:'8px'}}> Start Challenge</Link>
              </Button> */}
                  <Link to="/challenge/purchase">
                    <Button
                      type="primary"
                      className="custom-primary-button h-[50px] px-6 py-5 w-full rounded-xl"
                    >
                      Start Challenge
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
