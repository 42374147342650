import {
  Alert,
  Carousel,
  Cascader,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import {
  Discord,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
} from "../../../Components/icons/index";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "Components/ui/button";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import { Register } from "../../../API";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Country, State, City } from "country-state-city"
import { useAuth } from "context/AuthContext";

// import './SignInPage.css'; // Create a CSS file for styling
// const { Option } = Select;

// const countryOptions = [
//   { value: "usa", label: "USA" },
//   { value: "canada", label: "Canada" },
//   { value: "uk", label: "UK" },
//   { value: "australia", label: "Australia" },
//   { value: "germany", label: "Germany" },
//   { value: "france", label: "France" },
//   { value: "japan", label: "Japan" },
//   { value: "malaysia", label: "Malaysia" },
//   { value: "china", label: "China" },
//   { value: "india", label: "India" },
//   { value: "brazil", label: "Brazil" },
// ];

const getCountryList = () => Country.getAllCountries();
const getCountryCodeByName = (countryName) => getCountryList().find(x => x.name?.toLowerCase?.() === countryName?.toLowerCase?.())?.isoCode;
const getStateList = (countryCode) => State?.getStatesOfCountry(countryCode);
const getStateCodeByName = (countryCode, stateName) => getStateList(countryCode).find(x => x.name?.toLowerCase?.() === stateName?.toLowerCase?.())?.isoCode;
const getCityList = (countryCode, stateCode) => City.getCitiesOfState(
  countryCode,
  stateCode );


const privacyPolicy = {
  title: "Privacy Policy",
  body: `Our Privacy Policy below describes in detail how TradersFlow handles the collection, management and 
  protection of all confidential user information.`,
};

const filter = (inputValue, path) =>
  path.some(
    (option) =>
      option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );

const Signup = () => {
  const navigate = useNavigate();
  const { enteredEmail } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState({ message: "", type: "error" });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [selectedStateCode, setSelectedStateCode] = useState(null);
  const [filteredStateList, setFilteredStateList] = useState([]);
  const [filteredCityList, setFilteredCityList] = useState([]);
  const formRef = useRef(null);
  const [searchParams] = useSearchParams();

  // Access query parameters
  let email = searchParams.get('email'); // Get the value of 'email'

  useEffect(() => {
    console.log("query", email);
    email = email || enteredEmail;
    if(!email) {
      console.log("verify email 1st");
      navigate('/auth/pre-signup');
    }

    formRef.current.setFieldValue("email", email);
  }, [])

  useEffect(() => {
    const newStateList = !!selectedCountryCode ? getStateList(selectedCountryCode) : [];
    // console.log(newStateList)
    setFilteredStateList(newStateList);
  }, [selectedCountryCode]);

  useEffect(() => {
    const newCityList = !!selectedCountryCode && selectedStateCode ? 
      ((getCityList(selectedCountryCode, selectedStateCode)).length > 0 ? getCityList(selectedCountryCode, selectedStateCode) : [{ name: "-" }]) : 
      [];
    setFilteredCityList(newCityList);
  }, [selectedCountryCode, selectedStateCode]);


  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const onFinish = async (values) => {
    try {
      setFormError({ message: "", type: "error" });
      setIsLoading(true);
      await Register({
        ...values,
        country: Array.isArray(values.country)
          ? values.country?.pop?.()
          : values.country,
        state: Array.isArray(values.state)
          ? values.state?.pop?.()
          : values.state,
        city: Array.isArray(values.city)
          ? values.city?.pop?.()
          : values.city,
      });
      setFormError({
        message: "Successfully Registered!",
        type: "success",
      });
      navigate('/auth/signin');
    } catch (error) {
      console.log(error?.message, error?.response?.data);
      let errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.errors?.map((x) => x.message)?.join(". ") ||
        error?.response?.data?.message ||
        error.message ||
        "Register error.";
      // errorMessage = errorMessage === "E_INVALID_AUTH_PASSWORD" ? "Email and password do not match." : errorMessage;
      setFormError({
        message: errorMessage,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };


  const handleCountryChange = (value) => {
    console.log(`selected ${value}`);
    const myCountryCode = !!value ? getCountryCodeByName([...value]?.pop?.()) : null;
    console.log(selectedCountryCode , myCountryCode, value)
    if(selectedCountryCode !== myCountryCode) {
      setSelectedCountryCode(myCountryCode);
      setSelectedStateCode(null);
      // console.log(formRef.current)
      formRef.current.setFieldValue("state", "");
      formRef.current.setFieldValue("city", "");
    }
  };

  const handleStateChange = (value) => {
    console.log(`selected ${value}`);
    const myStateCode = !!value ? getStateCodeByName(selectedCountryCode, [...value]?.pop?.()) : null;
    if(selectedStateCode !== myStateCode) {
      setSelectedStateCode(myStateCode)
      formRef.current.setFieldValue("city", "");
    }
  };

  return (
    <>
      <Modal
        okText="Close"
        title={privacyPolicy.title}
        centered
        style={
          {
            // top: 20,
            // width: "100%",
            // height: "70%",
          }
        }
        // width={1000}
        // height={800}
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        {privacyPolicy.body}
      </Modal>
      <Link to="/auth/signin">
        <Image
          src="/images/logo.png"
          alt="logo"
          className="signinLogo"
          preview={false}
        />
      </Link>
      {/* <div className="signin-right-internal padding-md padding-sm"> */}
      <div className="signin-right-internal px-0 sm:px-[30px] md:px-[60px]">
        <h1 className="heading">
          Create Your <span>Account</span>
        </h1>
        <p className="subtitle">Please enter your detail</p>
        <Form name="normal_login" onFinish={onFinish} layout="vertical" ref={formRef}>
          <Row gutter={16}>
            <Col span={24}>
              {!!formError?.message && (
                <Space direction="vertical" size={12} className="w-100">
                  <Alert
                    message={formError.message || ""}
                    // description="This is an error message about copywriting."
                    type={formError?.type || "error"}
                    showIcon
                  />
                  <div />
                </Space>
              )}
            </Col>
            <Col xs={24} sm={12}>
              {/* First Name Field */}
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your First Name"
                  className="input-text"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              {/* Last Name Field */}
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your Last Name"
                  className="input-text"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Country, State, City, Auth Code, Phone, Email, Password, Confirm Password Fields*/}
           {/*  */}
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Please input your country!",
                  },
                ]}
              >
                {/* <Select style={{ display: 'block' }} placeholder='Select Country' onChange={handleChange}  className='input-text'>
           {countryOptions.map((country) => (
                <Option key={country.value} value={country.value}>
                  {country.label}
                </Option>
              ))}
            </Select> */}
                <Cascader
                  style={{ display: "block" }}
                  className="input-text"
                  placeholder="Select Country"
                  onChange={handleCountryChange}
                  showSearch={{ filter }}
                  multiple={false}
                  onSearch={(value) => console.log(value)}
                  // options={countryOptions}
                  options={ getCountryList()?.map(x => ({ label: x.name, value: x.name})) }
                  dropdownMenuColumnStyle={{ paddingRight: 40 }}
                /> 
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "Please input your State!",
                  },
                ]}
              >
                {/* <Input
                  placeholder="Enter your State"
                  className="input-text"
                /> */}
                <Cascader
                  style={{ display: "block" }}
                  className="input-text"
                  placeholder="Select State"
                  onChange={handleStateChange}
                  showSearch={{ filter }}
                  multiple={false}
                  onSearch={(value) => console.log(value)}
                  // options={countryOptions}
                  options={ filteredStateList.map(x => ({ label: x.name, value: x.name})) }
                  dropdownMenuColumnStyle={{ paddingRight: 40 }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "Please input your City!",
                  },
                ]}
              >
                {/* <Input
                  placeholder="Enter your city"
                  className="input-text"
                /> */}
                <Cascader
                  style={{ display: "block" }}
                  className="input-text"
                  placeholder="Select City"
                  onChange={handleChange}
                  showSearch={{ filter }}
                  multiple={false}
                  onSearch={(value) => console.log(value)}
                  // options={countryOptions}
                  options={filteredCityList.map(x => ({ label: x.name, value: x.name}))}
                  dropdownMenuColumnStyle={{ paddingRight: 40 }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="mobile"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your Phone Number!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your Phone Number"
                  className="input-text"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  readOnly={true}
                  placeholder="Enter your Email Address"
                  className="input-text"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="auth_code"
                label="Verification Code"
                rules={[
                  {
                    required: true,
                    message: "Please input your Verification Code!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your Verification Code"
                  className="input-text"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" className="input-text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two passwords do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="affiliate_code"
                label="Affiliate Code"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input your Affiliate Code!",
                //   },
                // ]}
              >
                <Input
                  placeholder="Enter your Affiliate Code"
                  className="input-text"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="mb-4">
              <Form.Item
                name="terms"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Checkbox>
                  I agree to the processing of personal data according to
                  {/* <span className='link' ><a href='#' target='blank' >Privacy Policy</a></span></Checkbox> */}
                  <span className="link">
                    <a
                      href="#"
                      target="_self"
                      onClick={() => setModalOpen(true)}
                    >
                      Privacy Policy
                    </a>
                  </span>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24} className="mb-4">
              <Form.Item
                name="remember"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Checkbox>
                  I acknowledge my name is correct and corresponds to the
                  government-issued identification
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {/* <Button type="primary" htmlType="submit" className="custom-primary-button w-100" loading={isLoading}>
              Register Now
            </Button> */}
            <Button
              loading={isLoading}
              className="w-full px-5 py-3 bg-primary-gradient text-white border-0 rounded-2xl text-sm font-medium shadow-none hover:bg-primary-gradient-hover"
            >
              Register Now
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div size="middle" className="social-icons">
        <li>
          <a href="#">
            {" "}
            <Instagram />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <Facebook />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <Twitter />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <LinkedIn />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <Discord />
          </a>{" "}
        </li>
      </div>
      <div className="signin-right-bottom">
        <p>
          Don’t have an account?
          <Link to="/auth/signin">
            <span> Login Now</span>
          </Link>
        </p>
      </div>
    </>
  );
};

export default Signup;
