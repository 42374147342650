// Header.js

import React from 'react';
import { Layout, Dropdown, Menu, Button, Avatar,Image ,Space} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles


const PageHeader = ({title,spanTitle,subheading}) => {
  return (
    <div className='pageHeader'>
        <h1>{title}<span>{spanTitle}</span></h1>
        <p>{subheading}</p>
    </div>
  );
};

export default PageHeader;
