import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  fetchAccounts,
  fetchMatrix,
  fetchoneAccount,
  getClosedOrderRecord,
  getLoss,
  getOpenOrderRecord,
  getPerformance,
  getPAndLData
} from "../../API/index";
import { useNavigate, useParams } from "react-router-dom";

import {
  Card,
  Space,
  Statistic,
  Table,
  Typography,
  Radio,
  Row,
  Col,
  Image,
  Input,
  Select,
  Divider,
} from "antd";
import { Link } from "react-router-dom";
import { Button } from "Components/ui/button";
import { useEffect, useState } from "react";
import PageHeader from "../../Components/common/pageHeader";
import UserCard from "../../Components/Cards/userCard";
import AccountCard from "../../Components/Cards/accountCard";
import Countdown from "../../Components/Cards/countdownCard";
import IconCard from "../../Components/Cards/iconCard";
import TradinginfoCard from "../../Components/Cards/tradinginfoCard";
import StatsCard from "../../Components/Cards/statsCard";
import AnalyticsLineChart from "../../Components/Cards/anaylyticsLineChart";
import { Tabs } from "antd";
import PLChart from "../../Components/Cards/PLChart";
import GenericTable from "../../Components/Tables";
import WinningDoughnutChart from "../../Components/Cards/winningDoughnutChart";
import TradingJournalTable from "../../Components/Tables/TradingJournalTable";
import dayjs from "dayjs";

function TradingJournal() {
  const openOrderTableColumns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Ticket",
      dataIndex: "ticket",
      key: "ticket",
    },
    {
      title: "Open Time",
      dataIndex: "openTime",
      key: "openTime",
    },
    {
      title: "Open Price",
      dataIndex: "openPrice",
      key: "openPrice",
    },
    {
      title: "Volume",
      dataIndex: "volume",
      key: "volume",
    },
    {
      title: "S/L",
      dataIndex: "s/l",
      key: "s/l",
    },
    {
      title: "T/P",
      dataIndex: "t/p",
      key: "t/p",
    },
    {
      title: "Current",
      dataIndex: "current",
      key: "current",
    },
    {
      title: "Profit/Loss",
      dataIndex: "profit/loss",
      key: "profit/loss",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (action) => {
        return (
          <div
            className={`actionBadge ${action === "Buy" ? "Buy" : "Sell"}`}
          >
            <span></span>
            {action}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <div
            className={`statusBadge ${status === "open" ? "open" : "close"}`}
          >
            <span></span>
            {status}
          </div>
        );
      },
    },
  ];

  const closedOrderTableColumns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Ticket",
      dataIndex: "ticket",
      key: "ticket",
    },
    {
      title: "Close Time",
      dataIndex: "closeTime",
      key: "closeTime",
    },
    {
      title: "Close Price",
      dataIndex: "closePrice",
      key: "closePrice",
    },
    {
      title: "Volume",
      dataIndex: "volume",
      key: "volume",
    },
    {
      title: "S/L",
      dataIndex: "s/l",
      key: "s/l",
    },
    {
      title: "T/P",
      dataIndex: "t/p",
      key: "t/p",
    },
    {
      title: "Profit/Loss",
      dataIndex: "profit/loss",
      key: "profit/loss",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (action) => {
        return (
          <div
            className={`actionBadge ${action === "Buy" ? "Buy" : "Sell"}`}
          >
            <span></span>
            {action}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <div
            className={`statusBadge ${status === "open" ? "open" : "close"}`}
          >
            <span></span>
            {status}
          </div>
        );
      },
    },
  ];

  const tableHeader = "Trading History";
  const plData = [10, -5, 8, -3, -15, 12, 5, 9, -7, 4];
  const [users, setUsers] = useState([]);
  const uniqueAccountName = users.map((x) => x.account_login);

  const [selectedUser, setSelectedUser] = useState(null);
  const [accountMatrix, setAccountMatrix] = useState([]);
  const [userLoginId, setUserLoginId] = useState(null);
  const [openOrder, setOpenOrder] = useState(null);
  const [closedOrder, setClosedOrder] = useState(null);
  const [tradingHistoryRecord, setTradingHistoryRecord] = useState([]);
  const [openOrderRecord, setOpenOrderRecord] = useState([]);
  const [accountId, setAccountId] = useState(null);
  const [dailyLoss, setDailyLoss] = useState(0);
  const [dailyPAndLData, setDailyPAndLData] = useState([]);

  const challengePhaseId = userLoginId?.user_challenge?.challenge_phase_id;
  const { id } = useParams();
  const navigation = useNavigate();

  useEffect(() => {
    const fetchUserid = async () => {
      try {
        const accountsData = await fetchAccounts();
        setUsers(accountsData);
        setSelectedUser(id || accountsData[0]?.account_login || null);
        //setSelectedUser(optionList || null);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserid();
  }, [id]);

  useEffect(() => {
    const fetchUserMatrix = async () => {
      console.log(selectedUser);
      if (selectedUser) {
        try {
          const matrixData = await fetchMatrix(selectedUser);
          setAccountMatrix(matrixData);
          setAccountId(matrixData?.account_id);
        } catch (error) {
          console.error("Error fetching matrix data:", error);
        }
      }
    };
    fetchUserMatrix();
  }, [selectedUser]);

  useEffect(() => {
    const getUserLoginId = async (acc_id) => {
      try {
        const data = await fetchoneAccount(acc_id);
        setUserLoginId(data);
        // console.log("the user accountlogin_id",account_id)
      } catch (error) {}
    };

    const getOpenOrder = async (acc_id) => {
      try {
        const openOrder = await getOpenOrderRecord(acc_id);
        setOpenOrder(openOrder);
      } catch (error) {}
    };

    const getClosedOrder = async (acc_id) => {
      try {
        const closedOrder = await getClosedOrderRecord(acc_id);
        setClosedOrder(closedOrder);
      } catch (error) {}
    };

    // const getAccountDailyPAndL = async (acc_id) => {
    //   try {
    //     const dailyPAndL = await getPAndLData(acc_id);
    //     const pAndLData = dailyPAndL.daily_pnls.map((x, index) => ({
    //       date: x.created_at,
    //       displayDate: x.created_at
    //       ? dayjs(x.created_at).format("YYYY-MM-DD")
    //       : "-",
    //       pnl: x.pnl
    //     }))
    //     //setPerformanceRecord(performance);
    //     setDailyPAndLData(pAndLData.reverse());
    //   } catch (error) {}
    // };
    // const account_id = accountMatrix?.statistics?.account_id;
    const account_id = accountMatrix?.account_id;
    setAccountId(account_id);
    getUserLoginId(account_id);
    getOpenOrder(account_id);
    getClosedOrder(account_id);
    // getAccountDailyPAndL(account_id);
  }, [accountMatrix, challengePhaseId, id]); 
    
  useEffect(() => {
    const getAccountDailyPAndL = async (acc_id) => {
      try {
        const dailyPAndL = await getPAndLData(acc_id);
        const pAndLData = dailyPAndL.daily_pnls.map((x, index) => ({
          date: x.date,
          displayDate: x.date
          ? dayjs(x.date).format("YYYY-MM-DD")
          : "-",
          pnl: x.pnl
        }))
        //setPerformanceRecord(performance);
        setDailyPAndLData(pAndLData);
      } catch (error) {}
    };

    const account_id = accountMatrix?.account_id;
    getAccountDailyPAndL(account_id);
  }, [accountMatrix]);

  useEffect(() => {
    let openRecord = [];
    if (openOrder && openOrder.count > 0) {
      openRecord = openOrder.orders.map((x, index) => ({
        key: index,
        symbol: x.symbol,
        ticket: x.position,
        openTime: x.time_create
          ? dayjs(x.time_create * 1000).format("YYYY-MM-DD hh:mmA")
          : "-",
          openPrice: (Math.floor(x.price_open * 100) / 100).toFixed(2),
          volume: x.volume / 10000,
          "s/l": (Math.floor(x.price_sl * 100) / 100).toFixed(2),
          "t/p": (Math.floor(x.price_tp * 100) / 100).toFixed(2),
          current: (Math.floor(x.price_current * 100) / 100).toFixed(2),
          "profit/loss": (Math.floor(x.profit * 100) / 100).toFixed(2),
        action: x.action == 1 ? 'Buy' : 'Sell',
        status: "open",
        accountLogin: x.login,
      }));
    }

    let closedRecord = [];
    if (closedOrder && closedOrder.count > 0) {
      closedRecord = closedOrder.orders.map((x, index) => ({
        key: index,
        symbol: x.symbol,
        ticket: x.deal,
        closeTime: x.time
          ? dayjs(x.time * 1000).format("YYYY-MM-DD hh:mmA")
          : "-",
          closePrice: (Math.floor(x.price * 100) / 100).toFixed(2),
          volume: x.volume / 10000,
          "s/l": (Math.floor(x.price_sl * 100) / 100).toFixed(2),
          "t/p": (Math.floor(x.price_tp * 100) / 100).toFixed(2),
          "profit/loss": (Math.floor(x.profit * 100) / 100).toFixed(2),
        action: x.action == 1 ? 'Buy' : 'Sell',
        status: "close",
        accountLogin: x.login,
      }));
    }

    // let outRecord = [];
    // outRecord = openRecord.concat(closedRecord);
    setOpenOrderRecord(openRecord);
    setTradingHistoryRecord(closedRecord);
  }, [openOrder, closedOrder]);

  const handleUserChange = (value) => {
    setSelectedUser(value);
    navigation(`/trading-journal/${value}`);
  };
  return (
    <>
      <div className="Matrix-page">
        {/* <div className="header">
          <div className="left-section"> */}
        <div className="flex justify-between items-center flex-col sm:flex-row mb-4">
          <div className="w-full flex gap-x-4 gap-y-4 justify-between items-center xl:gap-[20px] sm:justify-start mb-2 sm:mb-0">
            <PageHeader title={"Trading Journal"} />
            <Select
              value={selectedUser}
              onChange={handleUserChange}
              options={uniqueAccountName.map((packageName) => ({
                value: packageName,
                label: packageName,
              }))}
            ></Select>
          </div>
          <Link to="/challenge/purchase" className="w-full sm:w-auto">
            <Button
              type="primary"
              className="flex items-center px-5 py-3 bg-primary-gradient text-white border-0 rounded-xl text-xs font-medium shadow-none hover:bg-primary-gradient-hover w-full"
            >
              Start New Challenge
            </Button>
          </Link>
        </div>
        <Row gutter={16}>
          <Col span={24}>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
              <IconCard
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M7.08317 21.25C5.63817 21.25 4.3065 21.8025 3.30067 22.7092C3.074 22.9075 2.8615 23.12 2.67734 23.3608C1.884 24.3383 1.4165 25.5708 1.4165 26.9167C1.4165 30.0475 3.9665 32.5833 7.08317 32.5833C8.44317 32.5833 9.67567 32.1017 10.6532 31.3225C11.4465 30.6567 12.0698 29.7925 12.4098 28.8008C12.6365 28.22 12.7498 27.5825 12.7498 26.9167C12.7498 23.8 10.214 21.25 7.08317 21.25ZM10.4832 25.8258C10.4832 26.2225 10.1715 26.52 9.789 26.52C9.4065 26.52 9.09484 26.2225 9.09484 25.8258V25.6842C9.09484 25.5283 8.9815 25.4008 8.82567 25.4008H5.99234C6.134 25.67 6.10567 25.9958 5.879 26.2225C5.7515 26.35 5.5815 26.4208 5.38317 26.4208C5.21317 26.4208 5.029 26.35 4.9015 26.2225L3.8815 25.2025C3.82484 25.1458 3.76817 25.0608 3.72567 24.9758C3.669 24.8058 3.669 24.6217 3.72567 24.4517C3.76817 24.3808 3.81067 24.2817 3.8815 24.225L4.9015 23.2192C5.17067 22.9358 5.60984 22.9358 5.86484 23.2192C6.0915 23.4317 6.11984 23.7717 5.97817 24.0267H8.82567C9.73234 24.0267 10.469 24.7633 10.469 25.6842V25.8258H10.4832ZM8.78317 30.8267C8.599 30.8267 8.41484 30.77 8.28734 30.6283C8.06067 30.4158 8.03234 30.0758 8.174 29.8208H5.3265C4.41984 29.8208 3.68317 29.0842 3.68317 28.1633V28.0217C3.68317 27.625 3.99484 27.3275 4.37734 27.3275C4.75984 27.3275 5.0715 27.625 5.0715 28.0217V28.1633C5.0715 28.3192 5.18484 28.4467 5.34067 28.4467H8.18817C8.0465 28.1775 8.07484 27.8517 8.3015 27.625C8.57067 27.3558 9.00984 27.3558 9.26484 27.625L10.2848 28.645C10.3415 28.7017 10.3982 28.7867 10.4407 28.8717C10.4973 29.0417 10.4973 29.2258 10.4407 29.3958C10.3982 29.4667 10.3415 29.5517 10.2848 29.6083L9.26484 30.6142C9.13734 30.7558 8.95317 30.8267 8.78317 30.8267Z"
                      fill="white"
                    />
                    <path
                      d="M21.0373 5.59596V10.9793H18.9123V5.59596C18.9123 5.21346 18.5723 5.0293 18.3457 5.0293C18.2748 5.0293 18.204 5.04346 18.1332 5.0718L6.899 9.30763C6.14817 9.59096 5.6665 10.2993 5.6665 11.1068V12.056C4.37734 13.0193 3.5415 14.5635 3.5415 16.306V11.1068C3.5415 9.42096 4.57567 7.9193 6.14817 7.3243L17.3965 3.0743C17.7082 2.96096 18.034 2.9043 18.3457 2.9043C19.7623 2.9043 21.0373 4.0518 21.0373 5.59596Z"
                      fill="white"
                    />
                    <path
                      d="M30.4581 20.5418V21.9585C30.4581 22.341 30.1606 22.6527 29.764 22.6668H27.6956C26.9448 22.6668 26.2648 22.1143 26.2081 21.3777C26.1656 20.9385 26.3356 20.5277 26.619 20.2443C26.874 19.9752 27.2281 19.8335 27.6106 19.8335H29.7498C30.1606 19.8477 30.4581 20.1593 30.4581 20.5418Z"
                      fill="white"
                    />
                    <path
                      d="M27.5965 18.3457H29.0415C29.8207 18.3457 30.4582 17.7082 30.4582 16.929V16.3057C30.4582 13.3732 28.064 10.979 25.1315 10.979H8.86817C7.664 10.979 6.559 11.3757 5.6665 12.0557C4.37734 13.019 3.5415 14.5632 3.5415 16.3057V18.8273C3.5415 19.3657 4.10817 19.7057 4.61817 19.5357C5.4115 19.2665 6.24734 19.1248 7.08317 19.1248C11.3757 19.1248 14.8748 22.624 14.8748 26.9165C14.8748 27.9365 14.6057 29.0557 14.1807 30.0473C13.954 30.5573 14.3082 31.1665 14.8607 31.1665H25.1315C28.064 31.1665 30.4582 28.7723 30.4582 25.8398V25.5707C30.4582 24.7915 29.8207 24.154 29.0415 24.154H27.809C26.449 24.154 25.1457 23.3182 24.7915 22.0007C24.5082 20.924 24.8482 19.8757 25.5565 19.1957C26.0807 18.6573 26.8032 18.3457 27.5965 18.3457ZM19.8332 18.0623H12.7498C12.169 18.0623 11.6873 17.5807 11.6873 16.9998C11.6873 16.419 12.169 15.9373 12.7498 15.9373H19.8332C20.414 15.9373 20.8957 16.419 20.8957 16.9998C20.8957 17.5807 20.414 18.0623 19.8332 18.0623Z"
                      fill="white"
                    />
                  </svg>
                }
                heading={"Total P&L"}
                value={`$${parseFloat(
                  accountMatrix?.profit || 0
                ).toFixed(2)}`}
              />
              <IconCard
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M15.9377 11.4468V15.4984L14.5069 15.0026C13.7844 14.7476 13.3452 14.5068 13.3452 13.2743C13.3452 12.2684 14.096 11.4468 15.0169 11.4468H15.9377Z"
                      fill="white"
                    />
                    <path
                      d="M20.655 20.7256C20.655 21.7315 19.9042 22.5531 18.9833 22.5531H18.0625V18.5015L19.4933 18.9973C20.2158 19.2523 20.655 19.4931 20.655 20.7256Z"
                      fill="white"
                    />
                    <path
                      d="M22.936 2.8335H11.0643C5.90766 2.8335 2.8335 5.90766 2.8335 11.0643V22.936C2.8335 28.0927 5.90766 31.1668 11.0643 31.1668H22.936C28.0927 31.1668 31.1668 28.0927 31.1668 22.936V11.0643C31.1668 5.90766 28.0927 2.8335 22.936 2.8335ZM20.2018 17.0002C21.3068 17.3827 22.7802 18.1902 22.7802 20.726C22.7802 22.9077 21.0802 24.6785 18.9835 24.6785H18.0627V25.5002C18.0627 26.081 17.581 26.5627 17.0002 26.5627C16.4193 26.5627 15.9377 26.081 15.9377 25.5002V24.6785H15.4277C13.1043 24.6785 11.2202 22.7093 11.2202 20.301C11.2202 19.7202 11.6877 19.2385 12.2827 19.2385C12.8635 19.2385 13.3452 19.7202 13.3452 20.301C13.3452 21.5477 14.2802 22.5535 15.4277 22.5535H15.9377V17.751L13.7985 17.0002C12.6935 16.6177 11.2202 15.8102 11.2202 13.2743C11.2202 11.0927 12.9202 9.32183 15.0168 9.32183H15.9377V8.50016C15.9377 7.91933 16.4193 7.43766 17.0002 7.43766C17.581 7.43766 18.0627 7.91933 18.0627 8.50016V9.32183H18.5727C20.896 9.32183 22.7802 11.291 22.7802 13.6993C22.7802 14.2802 22.3127 14.7618 21.7177 14.7618C21.1368 14.7618 20.6552 14.2802 20.6552 13.6993C20.6552 12.4527 19.7202 11.4468 18.5727 11.4468H18.0627V16.2493L20.2018 17.0002Z"
                      fill="white"
                    />
                  </svg>
                }
                heading={"Profit Factor"}
                value={`${parseFloat(
                  accountMatrix.statistics?.profitFactor || 0
                ).toFixed(2)}`}
                toolTip={"gross profit / gross loss"}
              />
              <IconCard
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M22.936 2.8335H11.0643C5.90766 2.8335 2.8335 5.90766 2.8335 11.0643V22.9218C2.8335 28.0927 5.90766 31.1668 11.0643 31.1668H22.9218C28.0785 31.1668 31.1527 28.0927 31.1527 22.936V11.0643C31.1668 5.90766 28.0927 2.8335 22.936 2.8335ZM23.9135 16.3343C23.9135 16.8868 23.4743 17.326 22.9218 17.326C22.3693 17.326 21.9302 16.8868 21.9302 16.3343V16.0793L18.0768 19.9327C17.8643 20.1452 17.581 20.2443 17.2835 20.216C16.986 20.1877 16.7168 20.0318 16.561 19.7768L15.116 17.6235L11.7443 20.9952C11.546 21.1935 11.3052 21.2785 11.0502 21.2785C10.7952 21.2785 10.5402 21.1793 10.356 20.9952C9.9735 20.6127 9.9735 19.9893 10.356 19.5927L14.5777 15.371C14.7902 15.1585 15.0735 15.0593 15.371 15.0877C15.6685 15.116 15.9377 15.2718 16.0935 15.5268L17.5385 17.6802L20.5277 14.691H20.2727C19.7202 14.691 19.281 14.2518 19.281 13.6993C19.281 13.1468 19.7202 12.7077 20.2727 12.7077H22.9077C23.0352 12.7077 23.1627 12.736 23.2902 12.7785C23.531 12.8777 23.7293 13.076 23.8285 13.3168C23.8852 13.4443 23.8993 13.5718 23.8993 13.6993V16.3343H23.9135Z"
                      fill="white"
                    />
                  </svg>
                }
                heading={"Avg winning Trade"}
                value={`$${parseFloat(
                  accountMatrix.statistics?.averageWinningAmount || 0
                ).toFixed(2)}`}
              />
              <IconCard
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M22.936 2.8335H11.0643C5.90766 2.8335 2.8335 5.90766 2.8335 11.0643V22.9218C2.8335 28.0927 5.90766 31.1668 11.0643 31.1668H22.9218C28.0785 31.1668 31.1527 28.0927 31.1527 22.936V11.0643C31.1668 5.90766 28.0927 2.8335 22.936 2.8335ZM23.9135 20.301C23.9135 20.4285 23.8852 20.556 23.8427 20.6835C23.7435 20.9243 23.5452 21.1227 23.3043 21.2218C23.1768 21.2785 23.0493 21.2927 22.9218 21.2927H20.2868C19.7343 21.2927 19.2952 20.8535 19.2952 20.301C19.2952 19.7485 19.7343 19.3093 20.2868 19.3093H20.5418L17.5527 16.3202L16.1077 18.4735C15.9377 18.7143 15.6827 18.8843 15.3852 18.9127C15.0877 18.941 14.8043 18.8418 14.5918 18.6293L10.3702 14.4077C9.98766 14.0252 9.98766 13.4018 10.3702 13.0052C10.7527 12.6085 11.376 12.6227 11.7727 13.0052L15.1443 16.3768L16.5893 14.2235C16.7593 13.9827 17.0143 13.8127 17.3118 13.7843C17.6235 13.756 17.8927 13.8552 18.1052 14.0677L21.9585 17.921V17.666C21.9585 17.1135 22.3977 16.6743 22.9502 16.6743C23.5027 16.6743 23.9418 17.1135 23.9418 17.666V20.301H23.9135Z"
                      fill="white"
                    />
                  </svg>
                }
                heading={"Avg Losing Trade"}
                value={`$${parseFloat(
                  accountMatrix.statistics?.averageLossAmount || 0
                ).toFixed(2)}`}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16,16]} className="trading-view-section">
          <Col xs={24} xl={12}>
            <div className="account-card">
              <Row gutter={16}>
                <Col className="gutter-row" span={24}>
                  <div className="cardcontainer">
                    <div className="header">
                      <h6>Winning % By Trades</h6>
                    </div>
                    <Divider />
                    <Row className="doughnutchART">
                      <Col span={24}>
                        <WinningDoughnutChart const 
                        winPercentage={`${parseFloat(
                          accountMatrix.statistics?.profitTradesPercentage || 0
                        ).toFixed(2)}`} 
                        losePercentage={`${parseFloat(
                          accountMatrix.statistics?.lossTradesPercentage || 0
                        ).toFixed(2)}`}/>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} xl={12}>
            <div className="account-card">
              <Row gutter={16}>
                <Col className="gutter-row" span={24}>
                  <div className="cardcontainer">
                    <div className="header">
                      <h6>Daily Net Cumulative P&L</h6>
                    </div>
                    <Divider />
                    <Row className="formcontainer">
                      <Col span={24}>
                        <PLChart detailStats={dailyPAndLData} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div className="table-container">
              <Col span={24} className="d-flex">
                <PageHeader title={"Open Order"} />
              </Col>
              <TradingJournalTable
                header={tableHeader}
                columns={openOrderTableColumns}
                data={openOrderRecord}
                accountId={accountId}
                type={"open"}
              />
            </div>
          </Col>
        </Row>
        <div className="my-4"></div> 
        <Row gutter={16}>
          <Col span={24}>
            <div className="table-container">
              <PageHeader title={"Trading History"} />
              <TradingJournalTable
                header={tableHeader}
                columns={closedOrderTableColumns}
                data={tradingHistoryRecord} 
                // accountLogin={} 
                accountId={accountId}
                type={"closed"}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TradingJournal;
