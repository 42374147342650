import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
  BankOutlined,
  CheckOutlined
} from "@ant-design/icons";
import { fetchWithdrawData, fetchOneUserWithdraw, fetchAccounts, fetchMatrix, getActivePaymentMethods, resetChallenge, fetchAccountById, getAllChallengesV2, getPaymentMethodWithCurrencies, submitDeposits, getAllAuthChallengesV2, submitDepositsReset} from "../../API/index";
import axios from "axios";
import { Form, Card, Space, Statistic, Table, Typography, Radio, Row, Col, Button, Image, Input, Select, Divider, Checkbox, notification, Spin } from "antd";
import { useEffect, useState } from "react";
import PageHeader from "../../Components/common/pageHeader";
import PayoutCard from "../../Components/Cards/payoutCard";
import GenericTable from "../../Components/Tables";
// import WelcomeBanner from "../../Components/common/WelcomeBanner";
import "./index.css";


// const sampleTradingCapitalData = [5000, 10000, 25000, 50000, 100000, 200000];
// const sampleTradingCapitalData = [5000, 10000, 25000];
const sampleTradingCapitalData = [];
const samplePlatFormData = ["MT5"];

function Reset() {
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [UserWithdraw, setUserWithdraw] = useState([]);


  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [currentChallenge, setCurrentChallenge] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allChallengeData, setAllChallengeData] = useState({});
  const [selectedChallengeData, setSelectedChallengeData] = useState({});
  const [pricePay, setPricePay] = useState(0);
  const [triggerGetTradingCapitalData, setTriggerGetTradingCapitalData] = useState(false);
  const [formResetChallenge] = Form.useForm();
  const [toast, contextHolder] = notification.useNotification();
  const [tradingCapitalAddOnsData, setTradingCapitalAddOnsData] = useState({});
  const [promoCode, setPromoCode] = useState("10%OFF");

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [selectedPaymentMethodCurrency, setSelectedPaymentMethodCurrency] = useState({});

  const refundableFeeHintObj = {
    "100": "Pass assessment, get full refund from the initial price with your first profit share.", 
    "125": "Participate to get 25% extra bonus from the initial pricing, triple returns and full refund upon passing the assessment.",
  }
  const [refundableFeeHint, setRefundableFeeHint] = useState("");


  useEffect(() => {
    const getAccounts = async () => {
      try {
        const accRes = await fetchAccounts();
        setAccounts(accRes);
        console.log(accRes.map(x => ({
          account_login: x.account_login,
          id: x.id,
          status: x.status,
        })))
        const defaultAcc = (accRes?.filter(x => x?.status === 4)?.map(x => x.account_login) ?? [])?.[0];
        console.log("default acc:", defaultAcc)
        setSelectedAccount(defaultAcc);
      } catch (e) {
        console.log("fetch accs error: ", e)
      } finally {
        setIsLoading(false)
      }
    }
    getAccounts();
    getAllChallengesData();
    handleGetPaymentMethodWithCurrencies();
  }, []);

  useEffect(() => {
    const updateChallengeData = async () => {
      try {
        // get acc challenge
        const accId = accounts?.find(x => x.account_login === selectedAccount)?.id
        if (accId) {
          const acc = await fetchAccountById(accId);
          setCurrentChallenge(acc.user_challenge);

          // get active payments method
          const paymethods = await getActivePaymentMethods(selectedAccount);
          console.log("paymethods: ", paymethods)
        }
      } catch (e) {
        console.log(e);
      }
    }
    updateChallengeData();
  }, [selectedAccount]);

  useEffect(() => {
    if (!selectedChallengeData?.trading_capital_id) {
      setTradingCapitalAddOnsData({})
    } else if (!Object.keys(allChallengeData.addons ?? {})?.length < 1) {

    }
    if (selectedChallengeData && allChallengeData && currentChallenge) {
      console.log(selectedChallengeData)
      const challengePrice = allChallengeData[`step${currentChallenge?.challenge?.challenge_phase_type ?? 2}`]?.find(x => x.id === selectedChallengeData?.trading_capital_id)?.price ?? 0
      let price = challengePrice ?? 0;
      let addons = selectedChallengeData?.addons ?? {};
      if (Object.keys(addons).length > 0) {
        for (const key in addons) {
          price = price + addons[key].price;
        }
      }
      setPricePay(price * 0.9)
    }
  }, [selectedChallengeData, triggerGetTradingCapitalData]);

  useEffect(() => {
    if (triggerGetTradingCapitalData) {
      handleGetSelectedTradingCapitalData();
    }

    // if (triggerCalculatePricePay) {
    //   handleCalculatePricePay();
    // }
  }, [selectedChallengeData, triggerGetTradingCapitalData]);

  const getAllChallengesData = async () => {
    const allChallengeDataResult = await getAllAuthChallengesV2();
    const data = allChallengeDataResult?.data ?? {};
    if (Object.keys(data).length > 0) {
      console.log("Challenges: ", data)
      setAllChallengeData(data);
    }
  }

  const handleGetPaymentMethodWithCurrencies = async () => {
    try {
      const data = await getPaymentMethodWithCurrencies();
      let paymentMethodWithCurrencies = data?.payment_method_with_currencies ?? [];
      setPaymentMethods(paymentMethodWithCurrencies);
    } catch (error) {
      console.error('Error fetching Payment Method with currencies:', error);
    }
  }

  const onChangePaymentMethod = (e) => {
    let selectedPaymentMethodData = paymentMethods.find(item => item.id === e.target.value);
    setSelectedPaymentMethod(selectedPaymentMethodData);
    setSelectedPaymentMethodCurrency({});
  }

  const handleSelectedPaymentMethodCurrency = (value) => {
    let paymentMethodSupportedCurrencyData = selectedPaymentMethod?.payment_method_supported_currencies ?? [];
    if (paymentMethodSupportedCurrencyData.length > 0) {
      let selectedPaymentMethodCurrencyData = paymentMethodSupportedCurrencyData.find(item => item.currency_id === value)
      setSelectedPaymentMethodCurrency(selectedPaymentMethodCurrencyData);
    }
  }

  const handleSelectedChallengeData = async (value, selected_type) => {
    console.log({ [selected_type]: value })
    console.log(value, selectedChallengeData?.[selected_type], selected_type === "trading_capital_id" && value !== selectedChallengeData?.[selected_type])
    if (selected_type === "trading_capital_id" && value !== selectedChallengeData?.[selected_type]) {
      // handleGetSelectedTradingCapitalData();
      setTriggerGetTradingCapitalData(true);
    }
    if (Object.keys(selectedChallengeData).length === 0) {
      setSelectedChallengeData({ [selected_type]: value });
      setSelectedChallengeData((prevState) => ({ ...prevState, "platform": "MT5" }));
    } else {
      const currentChallengeType = `${(currentChallenge?.challenge?.challenge_phase_type ?? 2) === 1 ? "One" : "Two"}-Step`;
      if (currentChallengeType && selected_type === "challenge_type" && currentChallengeType !== value) {
        setSelectedChallengeData({});
        setSelectedChallengeData({ [selected_type]: value });
        // set the price to 0
        setPricePay(0);
      } else {
        setSelectedChallengeData((prevState) => ({ ...prevState, [selected_type]: value }));

        if (selected_type === "trading_capital_id") {
          setSelectedChallengeData((prevState) => ({ ...prevState, "platform": "MT5" }));

          setTriggerGetTradingCapitalData(true);
        }
      }
    }

  }


  const handleGetSelectedTradingCapitalData = async () => {
    console.log(selectedChallengeData?.trading_capital_id)
    if (selectedChallengeData?.trading_capital_id) {
      let selectedChallengeTypeData = allChallengeData[`step${currentChallenge?.challenge?.challenge_phase_type === 1 ? "1" : "2"}`];
      let selectedTradingCapitalData = selectedChallengeTypeData?.find(item => item.id === selectedChallengeData.trading_capital_id);
      console.log(selectedTradingCapitalData)
      if (selectedTradingCapitalData?.price) {
        setSelectedChallengeData((prevState) => ({ ...prevState, "price": selectedTradingCapitalData?.price }));
      }

      if (selectedTradingCapitalData?.account_balance) {
        setSelectedChallengeData((prevState) => ({ ...prevState, "account_balance": selectedTradingCapitalData?.account_balance }));
      }

      if (selectedTradingCapitalData?.title) {
        setSelectedChallengeData((prevState) => ({ ...prevState, "title": selectedTradingCapitalData?.title }));
      }

      if (selectedTradingCapitalData?.addons) {

        setTradingCapitalAddOnsData(selectedTradingCapitalData.addons);

        let addonsData = selectedTradingCapitalData.addons;
        if (Object.keys(addonsData).length > 0) {
          let defaultAddons = {};
          for (const key in addonsData) {
            // Filter the addons based on is_default property
            const defaultAddon = addonsData?.[key]?.find(addon => addon.is_default === 1);
            // If a default addon is found, add it to the defaultAddons object
            if (defaultAddon) {
              // defaultAddons[key] = {
              //   "id": defaultAddon.id,
              //   "name": defaultAddon.name,
              //   [key]: defaultAddon[key],
              //   "price": defaultAddon.price,
              // };
              switch(key){
                case "profit_target_step_two":
                    defaultAddons[key] = {
                        "id": defaultAddon.id,
                        "name": defaultAddon.name,
                        [key]: defaultAddon["profit_target"],
                        "price": defaultAddon.price,
                    };
                    break;
                default:
                    defaultAddons[key] = {
                        "id": defaultAddon.id,
                        "name": defaultAddon.name,
                        [key]: defaultAddon[key],
                        "price": defaultAddon.price,
                    };
                    break;
              }

              if (key === "refundable_fee") {
                setRefundableFeeHint(refundableFeeHintObj[(defaultAddon[key]).toString()]);
              }
            }
          }
          setSelectedChallengeData((prevState) => ({ ...prevState, "addons": defaultAddons }));
          // setTriggerCalculatePricePay(true);
        }
      }

      setTriggerGetTradingCapitalData(false);
    }
  }

  const handleSelectedAddOnData = (add_on_id, add_on_key, add_on_index) => {
    let addOnData = tradingCapitalAddOnsData[add_on_key];
    let selectedAddOnData = addOnData.find(item => item.id === add_on_id);
    let newSelectedAddon = {};
    if (selectedAddOnData) {
      switch(add_on_key){
        case "profit_target_step_two":
            newSelectedAddon = {
                "id": selectedAddOnData.id,
                "name": selectedAddOnData.name,
                [add_on_key]: selectedAddOnData["profit_target"],
                "price": selectedAddOnData.price,
            };
            break;
        default:
            newSelectedAddon = {
                "id": selectedAddOnData.id,
                "name": selectedAddOnData.name,
                [add_on_key]: selectedAddOnData[add_on_key],
                "price": selectedAddOnData.price,
            };
            break;
      }
      // newSelectedAddon = {
      //   "id": selectedAddOnData.id,
      //   "name": selectedAddOnData.name,
      //   [add_on_key]: selectedAddOnData[add_on_key],
      //   "price": selectedAddOnData.price,
      // };
    }
    setSelectedChallengeData((prevState) => ({
      ...prevState,
      addons: {
        ...prevState.addons, // Preserve other properties of addons
        [add_on_key]: newSelectedAddon // Dynamically update the addons object
      }
    }));

    if (add_on_key === "profit_target" && selectedChallengeData.challenge_type === "step2") {
      let tempAddonsData = tradingCapitalAddOnsData["profit_target_step_two"];
      let tempSelectedAddOnData = tempAddonsData[add_on_index];
      let tempNewSelectedAddon = {
        "id": tempSelectedAddOnData.id,
        "name": tempSelectedAddOnData.name,
        "profit_target_step_two": tempSelectedAddOnData["profit_target"],
        "price": tempSelectedAddOnData.price,
      };
      setSelectedChallengeData((prevState) => ({
        ...prevState,
        addons: {
          ...prevState.addons, // Preserve other properties of addons
          "profit_target_step_two": tempNewSelectedAddon // Dynamically update the addons object
        }
      }));
    }

    if (add_on_key === "refundable_fee") {
      setRefundableFeeHint(refundableFeeHintObj[(selectedAddOnData.refundable_fee).toString()]);
    }
    // setTriggerCalculatePricePay(true);
  }

  // const handleCalculatePricePay = () => {
  //   let price = selectedChallengeData?.price ?? 0;
  //   let addons = selectedChallengeData?.addons ?? {};
  //   if (Object.keys(addons).length > 0) {
  //     for (const key in addons) {
  //       price = price + addons[key].price;
  //     }
  //   }
  //   // setTriggerCalculatePricePay(false);
  //   setPricePay(price);
  // }

  const onSelectAccount = async (val) => {
    try {
      setSelectedAccount(val);
      setSelectedChallengeData({});
    } catch (e) {
      console.log(e)
    }
  }

  const keyTextToLabel = (key) => {
    // Split the key into words based on underscores
    const words = key.split('_');

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the capitalized words with spaces
    return capitalizedWords.join(' ');
  };

  const doResetChallenge = async () => {
    if (Object.keys(selectedPaymentMethod).length > 0) {
      if (Object.keys(selectedPaymentMethodCurrency).length > 0) {
        try {
          if (!selectedChallengeData?.trading_capital_id) {
            toast.error({
              message: "",
              description: "Please select a challenge.",
              placement: "top",
            });
            console.log("Please select a challenge.")
            return;
          }
    
          const payload = {
            challenge: selectedChallengeData?.trading_capital_id,
            account_login: selectedAccount,
            // payment_method_id: 3, // stripe, follow existing 
            payment_method_id: selectedPaymentMethod?.id ?? "",
            attachment: undefined,
            addons: Object.values(selectedChallengeData?.addons ?? {}).map(x => x.id),
            promocode: promoCode || "10%OFF", 
            currency_id: selectedPaymentMethodCurrency?.currency_id ?? "",
          }
         
          // const resetRes = await submitDeposits(payload);
          const resetRes = await submitDepositsReset(payload);
          // console.log("resetRes",resetRes); return false;
          if (resetRes) {
            let depositData = resetRes?.deposit ?? {};
            let paymentMethodId = depositData?.payment_method_id ?? "";
            let data = resetRes?.data ?? {};
            if (paymentMethodId == 6) {
              // payment method id = 6 【EeziePay】
              let formData = data;
              const form = document.createElement('form');
              form.action = "https://gogomart168com.com/fundtransfer.php"; 
              form.method = "POST";
              // form.target = "_blank";

              for (const key in formData) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = formData[key];
                form.appendChild(input);
              }

              document.body.appendChild(form);
              form.submit();
            } else {
              let redirect_url = data?.url ?? "";
              if (redirect_url.length > 0) {
                  window.location.href = redirect_url;
              }
            }
          }
          // console.log(payload)
          // const resetRes = await resetChallenge(payload);
          // console.log("reset challenge: ", resetRes);
          // if (resetRes) {
          //   window.location.href = resetRes.url
          // }
    
        } catch (e) {
          console.log(e)
    
          const errorCode = e.message
          const errorTranslate = e.translate_params
    
          toast.error({
            message: "",
            description: e?.response?.data?.message ?? "Reset Challenge Failed",
            placement: "top",
          });
          // SweetAlert.fire({
          //     title: t(errorCode, errorTranslate),
          //     icon: 'error'
          // })
        }
      } else {
        toast.error({
          message: "",
          description:
              'Please select your payment method currency.',
          placement: "top",
          style: {
              width: 420,
          },
        });
      }
    } else {
      toast.error({
        message: "",
        description:
            'Please select your payment method.',
        placement: "top",
      });
    }
  }


  const countdownTime = 'Feb 4, 2024 12:00:00'; // Example time, update it with your desired time
  const gmt = '+00:00'; // Example GMT, update it with your desired GMT


  const tableColumns = [
    {
      title: 'Sn',
      dataIndex: 'sn',
      key: 'sn',
    },
    {
      title: 'Requested At',
      dataIndex: 'requestedAt',
      key: 'requestedAt',
    },
    {
      title: 'Action Data',
      dataIndex: 'actionData',
      key: 'actionData',
    },
    {
      title: 'Charge',
      dataIndex: 'charge',
      key: 'charge',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        console.log('this is status', status);
        return <div className={`statusBadge ${status === 'Approved' ? ' open' : 'close'}`}><span></span>{status}</div>;
      },
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
    },
  ];
  const tableData = withdrawalData.map((withdrawal, index) => (
    {
      key: index.toString(),
      sn: index + 1,
      date: withdrawal.created_at.split('T')[0],
      method: withdrawal.payout_method,
      amount: `$ ${withdrawal.amount}`,
      currency: withdrawal.currency_unit,
      status: withdrawal.status === 1 ? 'Approved' : 'Rejected',
      invoice: withdrawal.invoice || 'open',
    }));



  const tableHeader = 'Trading History';

  const ChallengeComponent = () => {
    return <Form
      form={formResetChallenge}
      name="reset_challenge"
      requiredMark={false}
      onFinish={(vals) => doResetChallenge(vals, formResetChallenge)}
    >

      <Row span={24} gutter={16}>
        <Col xs={24} xl={12}>
          <div className="bg-white rounded-2xl mb-4 w-100  reset-challenge-page">
            <div className="p-[20px]">
              <div className="font-semibold text-lg">Current Challenge</div>
            </div>
            <hr />
            <div className="py-[20px] px-10">

              <div className='mb-6'>
                <div className='font-medium text-lg mb-3'>Challenge Type</div>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24}>
                    <div className={`checkbox-card cursor-not-allowed rounded-lg p-5 active`}>
                      <div className="border border-white rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                        <CheckOutlined className='text-[#DD0744]' disabled />
                      </div>
                      <div className={`text-base mt-2 text-white`}>{`${currentChallenge?.challenge?.challenge_phase_type === 1 ? "One" : "Two"}-Step`}</div>
                    </div>
                  </Col>

                </Row>
              </div>
              {currentChallenge?.challenge?.challenge_phase_type ? (
                <div className='mb-6'>
                  <div className='font-medium text-lg mb-3'>Trading Capital</div>
                  <Row gutter={[16, 16]}>
                    {allChallengeData[`step${currentChallenge?.challenge?.challenge_phase_type ?? 2}`]?.map((value, index) => {
                      return (
                        <Col key={index} xs={24} sm={24}>
                          <div
                            className={`checkbox-card ${currentChallenge?.challenge?.price > value?.price ? "cursor-not-allowed opacity-50" : "cursor-pointer"} rounded-lg p-5 ${value?.id} ${selectedChallengeData?.trading_capital_id === value?.id ? 'active' : ''}`}
                            onClick={() => {
                              if ((!selectedChallengeData || selectedChallengeData.trading_capital_id !== value?.id) && !(currentChallenge?.challenge?.price > value?.price)) {
                                handleSelectedChallengeData(value?.id, "trading_capital_id");
                              }
                            }}
                            disabled={currentChallenge?.challenge?.price > value?.price}
                          >
                            {selectedChallengeData?.trading_capital_id === value?.id ? (
                              <div className="border border-white rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                                <CheckOutlined className='text-[#DD0744]' />
                              </div>
                            ) : (
                              <div className="border border-[#717579] rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                              </div>
                            )}
                            <div className={`text-base mt-2 ${selectedChallengeData?.trading_capital_id === value?.id ? "text-white" : "text-black"}`}>
                              ${value?.account_balance ? (value.account_balance).toLocaleString() : ""}
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              ) : (
                <></>
              )}
              {currentChallenge?.challenge?.challenge_phase_type && samplePlatFormData?.length > 0 ? (
                <div className='mb-6'>
                  <div className='font-medium text-lg mb-3'>Platform</div>
                  <Row gutter={[16, 16]}>
                    {samplePlatFormData.map((value, index) => {
                      return (
                        <Col key={index} xs={24} sm={24}>
                          <div
                            className={`checkbox-card cursor-pointer rounded-lg p-5 ${selectedChallengeData?.platform === value ? 'active' : ''}`}
                            onClick={() => {
                              if (!selectedChallengeData || selectedChallengeData.platform !== value) {
                                handleSelectedChallengeData(value, "platform");
                              }
                            }}
                          >
                            {selectedChallengeData?.platform === value ? (
                              <div className="border border-white rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                                <CheckOutlined className='text-[#DD0744]' />
                              </div>
                            ) : (
                              <div className="border border-[#717579] rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                              </div>
                            )}
                            <div className={`text-base mt-2 ${selectedChallengeData?.platform === value ? "text-white" : "text-black"}`}>
                              {value}
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              ) : (
                <></>
              )}

              {/* Add-Ons */}
              {Object.keys(tradingCapitalAddOnsData).length > 0 && (
                <div className="bg-white rounded-2xl mb-3">
                  <div className="py-[20px]">
                    <div className="font-semibold text-lg">Add-Ons</div>
                  </div>
                  <hr />
                  <div className="py-[20px] px-10_">
                    <>
                      {Object.keys(tradingCapitalAddOnsData).map((key, index) => {

                        let addOnsTypeData = tradingCapitalAddOnsData[key];
                        return (
                          <div key={index} className='mb-3'>
                            <div className='font-medium text-lg mb-3'>{keyTextToLabel(key)}</div>
                            <Row gutter={[16, 16]}>
                              {addOnsTypeData.map((value2, index2) => {
                                let isDay = false;
                                let isPercentage = false;
                                let addOnName = value2?.name ?? "";
                                if (addOnName.includes('%')) {
                                  isPercentage = true;
                                }
                                if (addOnName.endsWith("days")) {
                                  isDay = true;
                                }
                                let addOnPrice = value2?.price ?? 0;

                                return (
                                  <Col key={index2} xs={24} sm={12}>
                                    <div
                                      className={`checkbox-card cursor-pointer rounded-lg p-4 ${selectedChallengeData?.addons?.[key]?.id && selectedChallengeData?.addons?.[key]?.id === value2?.id ? 'active' : ''}`}
                                      onClick={() => {
                                        if (!(key === "profit_target_step_two")) {
                                          if (!selectedChallengeData || selectedChallengeData?.addons[key]?.id !== value2?.id) {
                                              handleSelectedAddOnData(value2?.id, key, index2);
                                          }
                                        } 
                                      }}
                                    >
                                      {(selectedChallengeData?.addons?.[key]?.id && selectedChallengeData?.addons?.[key]?.id === value2?.id) ? (
                                        <div className='flex justify-between'>
                                          <div className="border border-white rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                                            <CheckOutlined className='text-[#DD0744]' />
                                          </div>
                                          {addOnPrice > 0 && (
                                            <div className='bg-[#FFFBFC] rounded-lg px-[10px] py-[2.5px] text-xs text-[#1D1E25]'>+${addOnPrice.toLocaleString()}</div>
                                          )}
                                          {addOnPrice < 0 && (
                                            <div className='bg-[#FFFBFC] rounded-lg px-[10px] py-[2.5px] text-xs text-[#DD0744]'>-${(Math.abs(addOnPrice)).toLocaleString()}</div>
                                          )}
                                        </div>
                                      ) : (
                                        <div className='flex justify-between'>
                                          <div className="border border-[#717579] rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                                          </div>
                                          {addOnPrice > 0 && (
                                            <div className='bg-[#FFFBFC] rounded-lg px-[10px] py-[2.5px] text-xs text-[#1D1E25]'>+${addOnPrice.toLocaleString()}</div>
                                          )}
                                          {addOnPrice < 0 && (
                                            <div className='bg-[#FFFBFC] rounded-lg px-[10px] py-[2.5px] text-xs text-[#DD0744]'>-${(Math.abs(addOnPrice)).toLocaleString()}</div>
                                          )}
                                        </div>
                                      )}
                                      <div className={`text-base mt-2 ${selectedChallengeData?.addons?.[key]?.id === value2?.id ? "text-white" : "text-black"}`}>
                                        {(() => {
                                          let result;
                                          switch (key) {
                                              case "profit_target_step_two":
                                                  result = value2["profit_target"];
                                                  break;
                                              default:
                                                  result = value2[key];
                                                  break;
                                          }
                                          return result;
                                        })()}
                                        {isPercentage ? "%" : (isDay ? ` day${+(value2?.[key]) > 0 ? "s" : ""}` : "")}
                                      </div>

                                    </div>
                                  </Col>
                                );
                              })}

                            </Row>
                            {key === "refundable_fee" && (
                              <div className='text-gray text-xs pt-3'>{refundableFeeHint}</div>
                            )}
                          </div>
                        );
                      })}
                    </>
                  </div>
                </div>
              )}
              {/* Payment Method */}
              {Object.keys(tradingCapitalAddOnsData).length > 0 && (
                <div className="bg-white rounded-2xl mb-3">
                  <div className="py-[20px]">
                    <div className="font-semibold text-lg">Payment Method</div>
                  </div>
                  <hr />
                  <div className="py-[20px] px-10_">
                    {paymentMethods && (
                      <Form.Item 
                          name="radioPaymentMethod" 
                          label={""}
                          rules={[
                              {
                                  required: true,
                                  message: "Payment Method is required",
                              },
                          ]}
                      >
                          <Radio.Group 
                              onChange={onChangePaymentMethod} 
                              value={selectedPaymentMethod?.id} 
                          >
                              {paymentMethods.map((value, index) => {
                                  return (
                                      <Radio 
                                          key={index} 
                                          value={value.id} 
                                          className='mb-2'
                                      >
                                          {value.name}
                                      </Radio>
                                  );
                              })}
                          </Radio.Group>
                      </Form.Item>
                    )}  

                    {selectedPaymentMethod && selectedPaymentMethod?.payment_method_supported_currencies?.length > 0 && (
                      <>
                          <div className='font-medium text-lg mb-3'>Currency</div>
                          <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4'>
                                  {selectedPaymentMethod.payment_method_supported_currencies.map((value, index) => {
                                      
                                      return (
                                          <div 
                                              key={index} 
                                              className={`checkbox-card cursor-pointer rounded-lg p-5 ${selectedPaymentMethodCurrency?.currency_id === value?.currency_id ? 'active' : ''}`}
                                              onClick={() => {
                                                  if (!selectedPaymentMethodCurrency || selectedPaymentMethodCurrency.currency_id !== value?.currency_id) {
                                                      handleSelectedPaymentMethodCurrency(value?.currency_id);
                                                  }
                                              }}
                                          >
                                              {selectedPaymentMethodCurrency?.currency_id === value?.currency_id ? (
                                                  <div className="border border-white rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                                                      <CheckOutlined className='text-[#DD0744]' />
                                                  </div>
                                              ) : (
                                                  <div className="border border-[#717579] rounded-[.32rem] bg-white w-[22px] h-[22px] flex justify-center">
                                                  </div>
                                              ) }
                                          
                                              <div className={`text-base mt-2 ${selectedPaymentMethodCurrency?.currency_id === value?.currency_id ? "text-white" : "text-black"}`}>
                                                  {value?.currency?.unit}
                                              </div>
                                          </div>
                                      )
                                  })}
                          </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              {/* Apply Coupon */}
              {/* <div className="bg-white rounded-2xl mb-4">
                <div className="py-[20px]">
                  <div className="font-semibold text-lg">Apply Coupon</div>
                </div>
                <hr />
                <div className="py-[20px] px-10_">
                  <Row gutter={16}>
                    <Col span={24}>
                      <div className="flex items-center">
                        <Form.Item
                          name="promo_code"
                          className="w-full"
                          label={<span>Promo Code</span>}
                        >
                          <Input
                            placeholder="Promo Code"
                            value={promoCode}
                            disabled
                            className="input-text h-[50px] focus-within:outline-0 focus-within:ring focus-within:ring-blue-500 focus-within:ring-opacity-10 focus-within:border-transparent focus-within:ring focus:ring-blue-500 focus:ring-opacity-10 focus:border-transparent hover:ring hover:ring-blue-500 hover:ring-opacity-10 hover:border-transparent"
                          />
                        </Form.Item>

                      </div>
                    </Col>
                  </Row>
                </div>
              </div> */}
            </div>

          </div>
        </Col>
        <Col xs={24} xl={12}>
          <div className={`relative top-auto xl:sticky xl:top-[6.875rem]`}>
            <div className={`bg-black rounded-xl px-8 py-6 mb-4`}>
              <div className="bg-white bg-opacity-20 py-3 rounded-xl leading-7 flex items-center flex-col">
                <div className="text-[rgba(255,255,255,.8)] mb-1">Your total price</div>
                <div className="text-white font-bold text-4xl min-h-10">
                  {"$" + (pricePay).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                </div>
                <div className="py-3 text-[rgba(255,255,255,.8)]">10% discount for reset account</div>
              </div>
              <div className="h-4"></div>
              <div className="text-white">
                <div>By confirming the order</div>
                <div>
                  <Form.Item
                    name="read_agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject('Please tick you have read all agreement.'),
                      },
                    ]}
                  >
                    <Checkbox className="text-white">
                      I have read all agreements. Privacy, Risk Disclosure, Terms and Condition, Refund Policy
                    </Checkbox>
                  </Form.Item>

                </div>
              </div>
              <div className="pt-2">
                <div className="w-9/12 m-auto">
                  <Button
                    type="primary"
                    className="custom-primary-button h-[50px] px-6  w-full rounded-xl"
                    onClick={formResetChallenge.submit}
                  // onClick={doResetChallenge}
                  >
                    Reset Challenge
                  </Button>
                </div>
              </div>

            </div>
          </div>
        </Col>

      </Row>
    </Form>
  }

  return (
    <>
      {contextHolder}
      {isLoading ?
        <Spin size="large" fullscreen /> :
        <div className="Payout-page reset-challenge-page">
          <div className="header">
            <div className="left-section">
              <PageHeader
                title={'Reset'}
              />
              <Select
                // defaultValue="1000812"
                value={selectedAccount}
                options={accounts?.filter(x => x?.status === 4)?.map(x => ({
                  value: x.account_login,
                  label: x.account_login
                })) ?? []}
                onChange={onSelectAccount}
              />
            </div>
          </div>
          <Row className="w-100" gutter={16}>
            <Col span={24}>
              {
                !currentChallenge?.challenge ? <></> :
                  <>
                    {ChallengeComponent()}
                    {/* <Col span={24}>
                  <WelcomeBanner
                  reset={true}
                  title="$89.1"
                    subheading={`10% discount for reset account`}
                    action={doResetChallenge}
                    />
                  </Col> */}
                  </>
              }

            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col span={24}>
                  <div className="table-container mt-4">
                    <PageHeader
                      title={'Reset History'}
                    />
                    <GenericTable header={tableHeader} columns={tableColumns} data={tableData} />

                  </div>
                </Col>
              </Row>
            </Col>

          </Row>
        </div >
      }
    </>
  );
}


export default Reset;




