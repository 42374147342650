import { Checkbox, Col, Divider, Form, Image, Input, Row } from "antd";
import {
  Discord,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
} from "../../../Components/icons/index";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useContext, useState } from "react";

import { Alert } from "antd";
import { Button } from "Components/ui/button";
import { Link } from "react-router-dom";
import { Space } from "antd";
import { UserEmailCode } from "../../../API";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

// import './SignInPage.css'; // Create a CSS file for styling

const ForgetPassword = () => {
  const { storeEnteredEmail } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState({ message: "", type: "error" });

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const onFinish = async (values) => {
    try {
      setFormError({ message: "", type: "error" });
      setIsLoading(true);
      const response = await UserEmailCode(values);

      console.log("Response:", response);
      if (response.success === true) {
        storeEnteredEmail(values.email);
        navigate("/auth/reset-password");
      } else {
        if (response.code === "try.again") {
          alert("Please try again after 2 minutes");
        }
      }
    } catch (error) {
      console.log(error?.message, error?.response?.data);
      let errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.errors?.map((x) => x.message)?.join(". ") ||
        error?.response?.data?.message ||
        error.message ||
        "Reset password failed";
      setFormError({
        message: errorMessage,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Link to="/auth/signin">
        <Image
          src="/images/logo.png"
          alt="logo"
          className="signinLogo"
          preview={false}
        />
      </Link>
      <div className="signin-right-internal padding-md padding-sm mb-form">
        <h1 className="heading">
          Forgot <span>Password?</span>
        </h1>
        <p className="subtitle">
          Enter your email address and submit the form to send a reset password
          link to your email address.
        </p>
        <Form
          name="normal_login"
          onFinish={onFinish}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {!!formError?.message && (
            <Space direction="vertical" size={12} className="w-100">
              <Alert
                message={formError.message || ""}
                // description="This is an error message about copywriting."
                type={formError?.type || "error"}
                showIcon
              />
              <div />
            </Space>
          )}
          <Form.Item
            name="email"
            label="Email Address"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input placeholder="Email" className="input-text" />
          </Form.Item>

          <Form.Item>
            {/* <Button type="primary" htmlType="submit" className="custom-primary-button w-100 " isLoading={isLoading}>
              Send Reset Email
            </Button> */}
            <Button
              loading={isLoading}
              className="w-full px-5 py-3 bg-primary-gradient text-white border-0 rounded-2xl text-sm font-medium shadow-none hover:bg-primary-gradient-hover"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div size="middle" className="social-icons mt-4">
        <li>
          <a href="#">
            {" "}
            <Instagram />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <Facebook />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <Twitter />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <LinkedIn />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <Discord />
          </a>{" "}
        </li>
      </div>
    </>
  );
};

export default ForgetPassword;
