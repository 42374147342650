import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation

const TradingJournalTable = ({ header, columns, data, accountId, type}) => {
  const navigate = useNavigate();

  const handleRowClick = (record) => {
    const { key } = record;
    let deal = record?.ticket ?? null;
    let accountLogin = record?.accountLogin ?? null;
    if (deal && accountLogin && accountId) {
      // Redirect to another page when a row is clicked with the id as a query parameter
      navigate(`/trade-detail?deal=${deal}&accountLogin=${accountLogin}&accountId=${accountId}&type=${type}`);
    }
  };

  // Add the row click handler to the table
  const rowProps = (record) => ({
    onClick: () => handleRowClick(record),
  });
  
  return (
    <div style={{ overflowY: 'auto' }}>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        onRow={(record, rowIndex) => rowProps(record)} 
        rowClassName={(record) => record && record.ticket && record.accountLogin && accountId ? 'cursor-pointer' : 'cursor-not-allowed'} 
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default TradingJournalTable;
