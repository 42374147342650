import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useCallback } from "react";

import { Button } from "Components/ui/button";
import { Link } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

const SideMenu = ({
  sidebarOpen,
  setSidebarOpen,
  navigation,
  currentNavItem,
}) => {
  const handleLinkClick = useCallback(
    (itemName) => {
      setSidebarOpen(false);
    },
    [setSidebarOpen]
  );

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 xl:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <Link to="/" onClick={() => handleLinkClick("Dashboard")}>
                        <img
                          className="h-12 w-auto"
                          src="/images/logo.png"
                          alt="Your Company"
                        />
                      </Link>
                    </div>
                    <div
                      className="w-auto h-32 bg-auto bg-no-repeat bg-center rounded-lg"
                      style={{
                        backgroundImage: 'url("/images/challenge.png")',
                      }}
                    >
                      <div className="flex flex-col justify-between h-full gap-y-5 p-4">
                        <div>
                          <div className="text-sm text-white font-semibold">
                            Trade Up To $200k With
                          </div>
                          <div className="text-sm text-white font-semibold">
                            TradersFlow
                          </div>
                        </div>
                        <div>
                          <Button className="flex items-center justify-between px-5 py-3 bg-primary-gradient text-white border-0 rounded-xl text-xs font-medium shadow-none hover:bg-primary-gradient-hover">
                            Start Challenge
                            <i className="fa-light fa-arrow-right ml-2"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name} className="mb-3">
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item === currentNavItem
                                      ? "bg-primary-gradient text-white"
                                      : "text-gray-500 hover:text-white hover:bg-primary-gradient",
                                    "group flex gap-x-3 rounded-xl p-2.5 text-xs leading-6 font-medium menu-icon-hover"
                                  )}
                                  onClick={() => handleLinkClick(item.name)}
                                >
                                  <item.icon
                                    className={classNames(
                                      "ml-2 hover:bg-primary-gradient",
                                      {
                                        "icon-current": item === currentNavItem,
                                      }
                                    )}
                                    aria-hidden="true"
                                    fill={classNames(
                                      item === currentNavItem
                                        ? "white"
                                        : "#717184"
                                    )}
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col pt-20">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4 bg-white">
            <div
              className="w-auto h-32 bg-auto bg-no-repeat bg-center rounded-lg mt-4"
              style={{ backgroundImage: 'url("/images/challenge.png")' }}
            >
              <div className="flex flex-col justify-between h-full gap-y-5 p-4">
                <div>
                  <div className="text-sm text-white font-semibold">
                    Trade Up To $200k With
                  </div>
                  <div className="text-sm text-white font-semibold">
                    TradersFlow
                  </div>
                </div>
                <div>
                  <Link to="/challenge/purchase">
                    <Button className="flex items-center justify-between px-5 py-3 bg-primary-gradient text-white border-0 rounded-2xl text-xs font-medium shadow-none hover:bg-primary-gradient-hover">
                      Start Challenge
                      <i className="fa-light fa-arrow-right ml-2"></i>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list">
                    {navigation.map((item) => (
                      <li key={item.name} className="mb-3">
                        <Link
                          to={item.href}
                          className={classNames(
                            item === currentNavItem
                              ? "bg-primary-gradient text-white"
                              : "text-gray-500 hover:text-white hover:bg-primary-gradient",
                            "group flex gap-x-3 rounded-xl p-2.5 text-xs leading-6 font-medium menu-icon-hover"
                          )}
                          onClick={() => handleLinkClick(item.name)}
                        >
                          <item.icon
                            className={classNames("ml-2", {
                              "icon-current": item === currentNavItem,
                            })}
                            aria-hidden="true"
                            fill={classNames(
                              item === currentNavItem ? "white" : "#717184"
                            )}
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
