import FacebookIcon from './facebook';
import TwitterIcon from './twitter';
import InstagramIcon from './instagram';
import DiscordIcon from './discord';
import LinkedinIcon from './linkedin';

const Icons = {
  Facebook: FacebookIcon,
  Twitter: TwitterIcon,
  Instagram: InstagramIcon,
  Discord:DiscordIcon,
  LinkedIn:LinkedinIcon
  // Add more icons here
};
export const {Facebook,Twitter,Instagram,Discord,LinkedIn}= Icons;