import {
  Alert,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Space,
} from "antd";
import {
  Discord,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
} from "../../../Components/icons/index";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import { Button } from "Components/ui/button";
import { Link } from "react-router-dom";
import { Login } from "../../../API";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

// import './SignInPage.css'; // Create a CSS file for styling
const token = sessionStorage.getItem("token");

const Signin = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const storedRememberMeConfig = localStorage.getItem("rememberMe");
  const rememberMeConfig = storedRememberMeConfig
    ? JSON.parse(storedRememberMeConfig)
    : null;

  const [rememberMe, setRememberMe] = useState(!!rememberMeConfig);
  const [formError, setFormError] = useState({ message: "", type: "error" });

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // console.log("Form instance:", form.getFieldsValue());

    form.setFieldsValue({
      email: rememberMeConfig?.email || form.getFieldValue("email") || "",
      remember: rememberMe,
    });
  }, [form, rememberMeConfig?.email, rememberMe]);

  const onFinish = async (values) => {
    console.log(values);
    try {
      setFormError({ message: "", type: "error" });
      setIsLoading(true);

      const response = await Login(values);
      login(response.user, response.token);

      if (!rememberMe) {
        localStorage.removeItem("rememberMe");
      } else {
        localStorage.setItem(
          "rememberMe",
          JSON.stringify({ email: values.email })
        );
      }

      navigate("/");
    } catch (error) {
      console.log(error?.message, error?.response?.data);
      let errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.errors?.map((x) => x.message)?.join(". ") ||
        error?.response?.data?.message ||
        error.message ||
        "Unauthorized";
      errorMessage =
        errorMessage.includes("E_INVALID_AUTH_PASSWORD") ||
        errorMessage.includes("E_INVALID_AUTH_UID")
          ? "Email and password do not match."
          : errorMessage;
      setFormError({
        message: "Invalid email or password",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Link to="/auth/signin">
        <Image
          src="/images/logo.png"
          alt="logo"
          className="signinLogo"
          preview={false}
        />
      </Link>
      {/* <div className="signin-right-internal padding-md padding-sm"> */}
      <div className="signin-right-internal px-0 sm:px-[30px] md:px-[60px]">
        <h1 className="heading">
          Login <span>Trader Flow</span>
        </h1>
        <p className="subtitle">Welcome back! please enter your detail</p>
        <Form
          form={form}
          name="normal_login"
          onFinish={(values) => onFinish(values, form)}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {!!formError?.message && (
            <Space direction="vertical" size={12} className="w-100">
              <Alert
                message={formError.message || ""}
                // description="This is an error message about copywriting."
                type={formError?.type || "error"}
                showIcon
              />
              <div />
            </Space>
          )}
          <Form.Item
            name="email"
            label="Email Address"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input placeholder="Email" className="input-text" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              suffix={
                <span
                  onClick={handlePasswordVisibility}
                  className="password-toggle"
                >
                  {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </span>
              }
            />
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "18px",
              }}
            >
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox onChange={(e) => setRememberMe(e.target.checked)}>
                  Remember me
                </Checkbox>
              </Form.Item>

              <a
                className="login-form-forgot"
                href="#"
                style={{ color: "#DD0744" }}
              >
                <div className="signin-right-bottom">
                  <p>
                    <Link to="/auth/forget-password ">
                      <span> Forgot password?</span>
                    </Link>
                  </p>
                </div>
              </a>
            </div>
          </Form.Item>

          <Form.Item>
            {/* <Button
              type="primary"
              htmlType="submit"
              className="custom-primary-button w-100"
              loading={isLoading}
            >
              Login Now
            </Button> */}
            <Button
              loading={isLoading}
              className="w-full px-5 py-3 bg-primary-gradient text-white border-0 rounded-2xl text-sm font-medium shadow-none hover:bg-primary-gradient-hover"
            >
              Login Now
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div size="middle" className="social-icons">
        <li>
          <a href="#">
            {" "}
            <Instagram />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <Facebook />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <Twitter />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <LinkedIn />
          </a>{" "}
        </li>
        <li>
          <a href="#">
            {" "}
            <Discord />
          </a>{" "}
        </li>
      </div>
      <div className="signin-right-bottom">
        <p>
          Don't have an account?{" "}
          <Link to="/auth/pre-signup">
            <span>Sign up</span>
          </Link>
        </p>
      </div>
    </>
  );
};

export default Signin;
