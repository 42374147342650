// Header.js

import React, { useState } from 'react';
import { Row,Col,Image,Dropdown, Button} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles
import CountdownCompetition from '../countdownCompetition';
import { Modal } from 'antd';

const VideoCard = ({video,text,img}) => {


const [modalVisible, setModalVisible] = useState(false);
const [selectedVideo, setSelectedVideo] = useState({});

const handleVideoClick = (video) => {
  setSelectedVideo(video);
  setModalVisible(true);
};

const handleModalCancel = () => {
  setModalVisible(false);
};



  return (
    <>
      <div className='video-card'>
        <div className="video-item" onClick={() => handleVideoClick(video)} >
          <img
            src={img} // Replace with the actual thumbnail URL or logic
            alt={video.title}
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <circle cx="24.835" cy="24.835" r="15.835" fill="url(#paint0_linear_1_2489)"/>
            <path d="M19.792 34.375L34.3753 25L19.792 15.625V34.375ZM25.0003 45.8333C22.1184 45.8333 19.41 45.2864 16.8753 44.1927C14.3406 43.0989 12.1357 41.6145 10.2607 39.7395C8.38574 37.8645 6.90137 35.6597 5.80762 33.125C4.71387 30.5902 4.16699 27.8819 4.16699 25C4.16699 22.118 4.71387 19.4097 5.80762 16.875C6.90137 14.3402 8.38574 12.1354 10.2607 10.2604C12.1357 8.38538 14.3406 6.901 16.8753 5.80725C19.41 4.7135 22.1184 4.16663 25.0003 4.16663C27.8823 4.16663 30.5906 4.7135 33.1253 5.80725C35.66 6.901 37.8649 8.38538 39.7399 10.2604C41.6149 12.1354 43.0993 14.3402 44.193 16.875C45.2868 19.4097 45.8337 22.118 45.8337 25C45.8337 27.8819 45.2868 30.5902 44.193 33.125C43.0993 35.6597 41.6149 37.8645 39.7399 39.7395C37.8649 41.6145 35.66 43.0989 33.1253 44.1927C30.5906 45.2864 27.8823 45.8333 25.0003 45.8333Z" fill="white"/>
            <defs>
              <linearGradient id="paint0_linear_1_2489" x1="8.99895" y1="24.8555" x2="54.9097" y2="25.51" gradientUnits="userSpaceOnUse">
                {/* <stop stop-color="#DD0744"/>
                <stop offset="0.9996" stop-color="#FF9A44"/> */}
                <stop stopColor="#DD0744"/>
                <stop offset="0.9996" stopColor="#FF9A44"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="video-title">{text}</div>
      </div>
      <Modal
        title={selectedVideo.title}
        // visible={modalVisible}
        open={modalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <iframe
          title={selectedVideo.title}
          width="100%"
          height="400"
          src={video}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </Modal>
    </>
  );
};

export default VideoCard;
