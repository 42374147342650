import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Space, Statistic, Table, Typography,Radio,Row,Col,Button,Image ,Input,Select, Divider} from "antd";
import { useEffect, useState } from "react";
import PageHeader from "../../Components/common/pageHeader";
import PackageCard from "../../Components/Cards/packageCard";
// import WelcomeBanner from "../../Components/common/WelcomeBanner";
import WelcomeBannerV2 from "../../Components/common/WelcomeBannerV2";
import CertificateCard from "../../Components/Cards/certificateCard";
import CompetitionCard from "../../Components/Cards/competitationCard";

const options = [
  {
    label: 'Competition List',
    value: 'competitionList',
  },
  {
    label: 'Upcoming',
    value: 'upcoming',
  },
  {
    label: 'In Progress',
    value: 'In Progress',
  },
  {
    label: 'Finished',
    value: 'finished',
  },
  
];

function Competition() {
  const [value4, setValue4] = useState('competitionList');
  const onChange4 = ({ target: { value } }) => {
    console.log('radio4 checked', value);
    setValue4(value);
  };
  const countdownTime = 'Feb 4, 2024 12:00:00'; // Example time, update it with your desired time
  const gmt = '+00:00'; // Example GMT, update it with your desired GMT


  const competitionsData=[
    {
      month:'December Competition',
      date:'Dec 1,2023 - Dec 31,2023',
      status:'Upcoming',
      contestants:'70517',
      prizePool:'$30,000+ Cash & $2.75M worth of Challenge Account',
      countdownTime:'Feb 4, 2024 12:00:00',
    },
    {
      month:'December Competition',
      date:'Dec 1,2023 - Dec 31,2023',
      status:'Finished',
      contestants:'70517',
      prizePool:'$30,000+ Cash & $2.75M worth of Challenge Account',
      countdownTime:'Feb 4, 2024 12:00:00',
    },
    {
      month:'December Competition',
      date:'Dec 1,2023 - Dec 31,2023',
      status:'In Progress',
      contestants:'70517',
      prizePool:'$30,000+ Cash & $2.75M worth of Challenge Account',
      countdownTime:'Feb 4, 2024 12:00:00',
    },
    {
      month:'December Competition',
      date:'Dec 1,2023 - Dec 31,2023',
      status:'Finished',
      contestants:'70517',
      prizePool:'$30,000+ Cash & $2.75M worth of Challenge Account',
      countdownTime:'Feb 4, 2024 12:00:00',
    },
    {
      month:'December Competition',
      date:'Dec 1,2023 - Dec 31,2023',
      status:'Finished',
      contestants:'70517',
      prizePool:'$30,000+ Cash & $2.75M worth of Challenge Account',
      countdownTime:'Feb 4, 2024 12:00:00',
    },
    {
      month:'December Competition',
      date:'Dec 1,2023 - Dec 31,2023',
      status:'Finished',
      contestants:'70517',
      prizePool:'$30,000+ Cash & $2.75M worth of Challenge Account',
      countdownTime:'Feb 4, 2024 12:00:00',
    },
    
  ]
  const filteredCompetitions = competitionsData.filter(data => {
    if (value4 === 'competitionList') {
      return true; 
    } else {
      return data.status.toLowerCase() === value4.toLowerCase();
    }
  });
  
  return (
    <>
      <div className="Competition-page">
        <div className="banner">
          <WelcomeBannerV2
            title={'Trader Flow Monthly Competitions'} 
            subheading={`Win big every month in FundedNext's Free Trading Competition! Unleash your trading potential, win cash prizes, and earn challenge accounts. Register now!`}
          />
        </div>
        <div className="header">
          <Row gutter={16} className="w-100">
            <Col xs={24} md={12}>
              <PageHeader
                title={'Competition'}
                />
            </Col>
            {/* <Col span={12} className="right-section"> */}
            <Col xs={24} md={12} className="flex justify-start items-center md:justify-end">
              <Radio.Group
                options={options}
                onChange={onChange4}
                value={value4}
                optionType="button"
                buttonStyle="solid"
                className="customradio"
              />
            </Col>
          </Row>
        </div>
        <Row gutter={30}>
          {
            filteredCompetitions.map((data,i)=>{
              return(
                // <Col span={6}>
                <Col xs={24} sm={12} lg={12} xl={8} xxl={6} key={i}>
                  <CompetitionCard 
                    month={data.month} 
                    color={data.color} 
                    date={data.date} 
                    countdownTime={data.countdownTime}  
                    status={data.status} 
                    prizePool={data.prizePool} 
                    contestants={data.contestants}
                    gmt={gmt}
                  />
                </Col>
              )
            })
          }
        </Row>
      </div>
    
    </>
    
  );
}


export default Competition;
