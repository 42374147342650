import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [enteredEmail, setEnteredEmail] = useState("");
  const [currentUser, setCurrentUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [authToken, setAuthToken] = useState(localStorage.getItem("token"));

  const updateLocalStorageToken = useCallback((token) => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, []);

  const updateLocalStorageUser = useCallback((user) => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, []);

  const login = useCallback(
    (user, token) => {
      setCurrentUser(user);
      setAuthToken(token);
      updateLocalStorageUser(user);
      updateLocalStorageToken(token);
    },
    [updateLocalStorageUser, updateLocalStorageToken]
  );

  const logout = useCallback(() => {
    setCurrentUser(null);
    setAuthToken(null);
    updateLocalStorageUser(null);
    updateLocalStorageToken(null);
  }, [updateLocalStorageUser, updateLocalStorageToken]);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedToken = localStorage.getItem("token");
    if (storedUser && !currentUser) {
      const user = JSON.parse(storedUser);
      login(user, storedToken);
    }
  }, [currentUser, login]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        authToken,
        login,
        logout,
        enteredEmail,
        storeEnteredEmail: setEnteredEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
