// Header.js

import React from 'react';
import { Row,Col,Image,Dropdown,Button,Divider,Input,Tooltip} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles
import  CopyableInput  from '../../common/copyInput';





const PayoutCard = ({withdrawalData}) => {
  const withdrawal = withdrawalData;
  console.log("the witdraw is ",withdrawal)
  return (
    <div className='payout-card '>
      <Row gutter={16} >
        <Col className="gutter-row" span={24}>
          <div className="cardcontainer">
              <div className="header">
                <h6>Payout Detail</h6>
              </div>
              <Divider/>
              <Row gutter={[16,16]} className='custom-row'>
                <Col xs={24} md={12} xl={8} >
                  <div className='payoutCard active'>
                    <div className='header'>
                      <div className='imageGroup'>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                          <path d="M18.9582 37.4164C29.1527 37.4164 37.4164 29.1527 37.4164 18.9582C37.4164 8.76393 29.1527 0.5 18.9582 0.5C8.76411 0.5 0.5 8.76392 0.5 18.9582C0.5 29.1527 8.76411 37.4164 18.9582 37.4164Z" fill="white" stroke="#DE0C44"/>
                          <path d="M18.2151 29.5056V9.25061H19.5127V29.5056H18.2151ZM21.6569 15.9363C21.5936 15.2981 21.322 14.8023 20.842 14.4489C20.362 14.0955 19.7105 13.9188 18.8877 13.9188C18.3286 13.9188 17.8565 13.9979 17.4714 14.1561C17.0864 14.3091 16.791 14.5227 16.5853 14.797C16.3848 15.0713 16.2846 15.3825 16.2846 15.7306C16.274 16.0207 16.3347 16.2739 16.4666 16.4902C16.6037 16.7065 16.791 16.8937 17.0283 17.052C17.2657 17.2049 17.54 17.3394 17.8512 17.4555C18.1624 17.5662 18.4947 17.6612 18.8481 17.7403L20.3039 18.0884C21.0108 18.2467 21.6596 18.4577 22.2503 18.7214C22.8411 18.9851 23.3527 19.3095 23.7853 19.6946C24.2178 20.0797 24.5527 20.5333 24.7901 21.0555C25.0328 21.5777 25.1567 22.1764 25.162 22.8515C25.1567 23.8432 24.9035 24.703 24.4024 25.4309C23.9066 26.1535 23.1892 26.7153 22.2503 27.1162C21.3167 27.5118 20.1905 27.7096 18.8719 27.7096C17.5637 27.7096 16.4244 27.5091 15.4538 27.1082C14.4885 26.7074 13.7343 26.114 13.191 25.328C12.6529 24.5368 12.3707 23.5583 12.3444 22.3926H15.6595C15.6965 22.9359 15.8521 23.3896 16.1263 23.7535C16.4059 24.1122 16.7778 24.3838 17.242 24.5685C17.7114 24.7478 18.2415 24.8375 18.8323 24.8375C19.4125 24.8375 19.9163 24.7531 20.3435 24.5843C20.776 24.4155 21.111 24.1808 21.3483 23.8801C21.5857 23.5794 21.7044 23.234 21.7044 22.8436C21.7044 22.4797 21.5963 22.1737 21.38 21.9258C21.169 21.6779 20.8578 21.4669 20.4464 21.2928C20.0402 21.1188 19.5417 20.9605 18.951 20.8181L17.1866 20.375C15.8204 20.0427 14.7417 19.5232 13.9505 18.8164C13.1593 18.1095 12.7663 17.1574 12.7716 15.9601C12.7663 14.979 13.0274 14.1218 13.5549 13.3886C14.0877 12.6555 14.8182 12.0831 15.7466 11.6717C16.6749 11.2603 17.7299 11.0546 18.9114 11.0546C20.1141 11.0546 21.1637 11.2603 22.0604 11.6717C22.9624 12.0831 23.664 12.6555 24.1651 13.3886C24.6662 14.1218 24.9246 14.9711 24.9404 15.9363H21.6569Z" fill="#DE0C44"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
                          <path d="M36.218 18.0416C36.218 27.9599 28.1782 35.9998 18.2598 35.9998C8.34188 35.9998 0.301636 27.9599 0.301636 18.0416C0.301636 8.12344 8.34188 0.083374 18.2598 0.083374C28.1782 0.083374 36.218 8.12344 36.218 18.0416Z" fill="white"/>
                          <path d="M26.6878 9.13904H9.68323V13.2443H16.1329V19.2782H20.2382V13.2443H26.6878V9.13904Z" fill="#DE0C44"/>
                          <path d="M18.2245 19.9209C12.8892 19.9209 8.56357 19.0765 8.56357 18.0347C8.56357 16.9932 12.889 16.1486 18.2245 16.1486C23.5599 16.1486 27.8853 16.9932 27.8853 18.0347C27.8853 19.0765 23.5599 19.9209 18.2245 19.9209ZM29.0722 18.3492C29.0722 17.0059 24.2156 15.9171 18.2245 15.9171C12.2337 15.9171 7.37671 17.0059 7.37671 18.3492C7.37671 19.5321 11.1427 20.5176 16.1331 20.736V29.398H20.238V20.7394C25.2668 20.5279 29.0722 19.5382 29.0722 18.3492Z" fill="#DE0C44"/>
                        </svg> */}
                        <img src='/images/group_1000007488.png' className='w-[35px] h-auto'/>
                      </div>
                      <Tooltip title="Withdrawable Profit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" className='tooltip'>
                          <path d="M12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22ZM12.75 16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16L11.25 11C11.25 10.59 11.59 10.25 12 10.25C12.41 10.25 12.75 10.59 12.75 11L12.75 16ZM11.08 7.62C11.13 7.49 11.2 7.39 11.29 7.29C11.39 7.2 11.5 7.13 11.62 7.08C11.74 7.03 11.87 7 12 7C12.13 7 12.26 7.03 12.38 7.08C12.5 7.13 12.61 7.2 12.71 7.29C12.8 7.39 12.87 7.49 12.92 7.62C12.97 7.74 13 7.87 13 8C13 8.13 12.97 8.26 12.92 8.38C12.87 8.5 12.8 8.61 12.71 8.71C12.61 8.8 12.5 8.87 12.38 8.92C12.14 9.02 11.86 9.02 11.62 8.92C11.5 8.87 11.39 8.8 11.29 8.71C11.2 8.61 11.13 8.5 11.08 8.38C11.03 8.26 11 8.13 11 8C11 7.87 11.03 7.74 11.08 7.62Z" fill="#717184"/>
                        </svg>
                      </Tooltip>
                    </div>
                    <h6>{`$${parseFloat(withdrawal?.withdrawable_amount || 0).toFixed(2)}`}</h6>
                    <p>Withdrawable Profit</p>

                  </div>
                </Col>
                <Col xs={24} md={12} xl={8} >
                  <div className='payoutCard'>
                    <div className='header'>
                      <div className='imageGroup'>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none" >
                          <path d="M18.4129 36.3259C28.3062 36.3259 36.3259 28.3062 36.3259 18.4129C36.3259 8.51981 28.3062 0.5 18.4129 0.5C8.51998 0.5 0.5 8.5198 0.5 18.4129C0.5 28.3062 8.51998 36.3259 18.4129 36.3259Z" fill="#DE0C44" stroke="#F6F6F6"/>
                          <path d="M17.6925 28.64V9H18.9507V28.64H17.6925ZM21.0298 15.4827C20.9684 14.8639 20.705 14.3831 20.2396 14.0404C19.7742 13.6977 19.1425 13.5264 18.3446 13.5264C17.8025 13.5264 17.3447 13.6031 16.9714 13.7566C16.598 13.9049 16.3116 14.112 16.1121 14.378C15.9178 14.6439 15.8206 14.9457 15.8206 15.2833C15.8104 15.5646 15.8692 15.8101 15.997 16.0198C16.13 16.2295 16.3116 16.411 16.5417 16.5645C16.7719 16.7128 17.0379 16.8432 17.3396 16.9557C17.6414 17.0631 17.9636 17.1552 18.3063 17.2319L19.7179 17.5695C20.4033 17.7229 21.0324 17.9275 21.6052 18.1832C22.178 18.439 22.6741 18.7535 23.0935 19.1269C23.5129 19.5002 23.8377 19.9401 24.0679 20.4464C24.3031 20.9528 24.4233 21.5333 24.4284 22.188C24.4233 23.1495 24.1778 23.9832 23.6919 24.689C23.2112 25.3897 22.5156 25.9344 21.6052 26.3231C20.6999 26.7067 19.6079 26.8985 18.3293 26.8985C17.0609 26.8985 15.9561 26.7041 15.015 26.3154C14.0791 25.9267 13.3477 25.3513 12.8209 24.5892C12.2992 23.8221 12.0256 22.8733 12 21.743H15.2145C15.2503 22.2698 15.4012 22.7096 15.6672 23.0625C15.9382 23.4103 16.2988 23.6737 16.7489 23.8527C17.2041 24.0266 17.7181 24.1136 18.2909 24.1136C18.8535 24.1136 19.342 24.0318 19.7563 23.8681C20.1757 23.7044 20.5004 23.4768 20.7306 23.1853C20.9608 22.8938 21.0758 22.5588 21.0758 22.1803C21.0758 21.8274 20.971 21.5307 20.7613 21.2903C20.5567 21.05 20.2549 20.8454 19.856 20.6766C19.4622 20.5078 18.9788 20.3544 18.406 20.2163L16.6952 19.7867C15.3705 19.4644 14.3246 18.9607 13.5574 18.2753C12.7902 17.5899 12.4092 16.6668 12.4143 15.5057C12.4092 14.5544 12.6623 13.7233 13.1738 13.0124C13.6904 12.3015 14.3987 11.7465 15.2989 11.3476C16.1991 10.9487 17.222 10.7492 18.3677 10.7492C19.5338 10.7492 20.5516 10.9487 21.4211 11.3476C22.2957 11.7465 22.9759 12.3015 23.4618 13.0124C23.9477 13.7233 24.1983 14.5468 24.2136 15.4827H21.0298Z" fill="white"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 36 35" fill="none">
                          <path d="M35.6022 17.4129C35.6022 27.0301 27.8065 34.8259 18.1893 34.8259C8.57249 34.8259 0.776367 27.0301 0.776367 17.4129C0.776367 7.79595 8.57249 0 18.1893 0C27.8065 0 35.6022 7.79595 35.6022 17.4129Z" fill="#DE0C44"/>
                          <path d="M26.3614 8.78076H9.87305V12.7614H16.1269V18.6121H20.1075V12.7614H26.3614V8.78076Z" fill="white"/>
                          <path d="M18.1552 19.2352C12.9818 19.2352 8.78754 18.4165 8.78754 17.4064C8.78754 16.3964 12.9816 15.5775 18.1552 15.5775C23.3286 15.5775 27.5226 16.3964 27.5226 17.4064C27.5226 18.4165 23.3286 19.2352 18.1552 19.2352ZM28.6735 17.7113C28.6735 16.4088 23.9643 15.353 18.1552 15.353C12.3462 15.353 7.63672 16.4088 7.63672 17.7113C7.63672 18.8583 11.2884 19.8139 16.1273 20.0256V28.4246H20.1075V20.0289C24.9837 19.8238 28.6735 18.8642 28.6735 17.7113Z" fill="white"/>
                        </svg> */}
                        <img src='/images/group_1000007487.png' className='w-[35px] h-auto'/>
                      </div>
                      <Tooltip title="Pending Amount">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                          <path d="M12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22ZM12.75 16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16L11.25 11C11.25 10.59 11.59 10.25 12 10.25C12.41 10.25 12.75 10.59 12.75 11L12.75 16ZM11.08 7.62C11.13 7.49 11.2 7.39 11.29 7.29C11.39 7.2 11.5 7.13 11.62 7.08C11.74 7.03 11.87 7 12 7C12.13 7 12.26 7.03 12.38 7.08C12.5 7.13 12.61 7.2 12.71 7.29C12.8 7.39 12.87 7.49 12.92 7.62C12.97 7.74 13 7.87 13 8C13 8.13 12.97 8.26 12.92 8.38C12.87 8.5 12.8 8.61 12.71 8.71C12.61 8.8 12.5 8.87 12.38 8.92C12.14 9.02 11.86 9.02 11.62 8.92C11.5 8.87 11.39 8.8 11.29 8.71C11.2 8.61 11.13 8.5 11.08 8.38C11.03 8.26 11 8.13 11 8C11 7.87 11.03 7.74 11.08 7.62Z" fill="#717184"/>
                        </svg>
                      </Tooltip>
                    </div>
                    <h6>{`$${parseFloat(withdrawal?.pending_withdraw || 0).toFixed(2)}`}</h6>
                    <p>Pending Amount</p>
                  </div>
                </Col>
                <Col xs={24} md={12} xl={8} >
                  <div className='payoutCard'>
                    <div className='header'>
                      <div className='imageGroup'>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none" >
                          <path d="M18.4129 36.3259C28.3062 36.3259 36.3259 28.3062 36.3259 18.4129C36.3259 8.51981 28.3062 0.5 18.4129 0.5C8.51998 0.5 0.5 8.5198 0.5 18.4129C0.5 28.3062 8.51998 36.3259 18.4129 36.3259Z" fill="#DE0C44" stroke="#F6F6F6"/>
                          <path d="M17.6925 28.64V9H18.9507V28.64H17.6925ZM21.0298 15.4827C20.9684 14.8639 20.705 14.3831 20.2396 14.0404C19.7742 13.6977 19.1425 13.5264 18.3446 13.5264C17.8025 13.5264 17.3447 13.6031 16.9714 13.7566C16.598 13.9049 16.3116 14.112 16.1121 14.378C15.9178 14.6439 15.8206 14.9457 15.8206 15.2833C15.8104 15.5646 15.8692 15.8101 15.997 16.0198C16.13 16.2295 16.3116 16.411 16.5417 16.5645C16.7719 16.7128 17.0379 16.8432 17.3396 16.9557C17.6414 17.0631 17.9636 17.1552 18.3063 17.2319L19.7179 17.5695C20.4033 17.7229 21.0324 17.9275 21.6052 18.1832C22.178 18.439 22.6741 18.7535 23.0935 19.1269C23.5129 19.5002 23.8377 19.9401 24.0679 20.4464C24.3031 20.9528 24.4233 21.5333 24.4284 22.188C24.4233 23.1495 24.1778 23.9832 23.6919 24.689C23.2112 25.3897 22.5156 25.9344 21.6052 26.3231C20.6999 26.7067 19.6079 26.8985 18.3293 26.8985C17.0609 26.8985 15.9561 26.7041 15.015 26.3154C14.0791 25.9267 13.3477 25.3513 12.8209 24.5892C12.2992 23.8221 12.0256 22.8733 12 21.743H15.2145C15.2503 22.2698 15.4012 22.7096 15.6672 23.0625C15.9382 23.4103 16.2988 23.6737 16.7489 23.8527C17.2041 24.0266 17.7181 24.1136 18.2909 24.1136C18.8535 24.1136 19.342 24.0318 19.7563 23.8681C20.1757 23.7044 20.5004 23.4768 20.7306 23.1853C20.9608 22.8938 21.0758 22.5588 21.0758 22.1803C21.0758 21.8274 20.971 21.5307 20.7613 21.2903C20.5567 21.05 20.2549 20.8454 19.856 20.6766C19.4622 20.5078 18.9788 20.3544 18.406 20.2163L16.6952 19.7867C15.3705 19.4644 14.3246 18.9607 13.5574 18.2753C12.7902 17.5899 12.4092 16.6668 12.4143 15.5057C12.4092 14.5544 12.6623 13.7233 13.1738 13.0124C13.6904 12.3015 14.3987 11.7465 15.2989 11.3476C16.1991 10.9487 17.222 10.7492 18.3677 10.7492C19.5338 10.7492 20.5516 10.9487 21.4211 11.3476C22.2957 11.7465 22.9759 12.3015 23.4618 13.0124C23.9477 13.7233 24.1983 14.5468 24.2136 15.4827H21.0298Z" fill="white"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 36 35" fill="none">
                          <path d="M35.6022 17.4129C35.6022 27.0301 27.8065 34.8259 18.1893 34.8259C8.57249 34.8259 0.776367 27.0301 0.776367 17.4129C0.776367 7.79595 8.57249 0 18.1893 0C27.8065 0 35.6022 7.79595 35.6022 17.4129Z" fill="#DE0C44"/>
                          <path d="M26.3614 8.78076H9.87305V12.7614H16.1269V18.6121H20.1075V12.7614H26.3614V8.78076Z" fill="white"/>
                          <path d="M18.1552 19.2352C12.9818 19.2352 8.78754 18.4165 8.78754 17.4064C8.78754 16.3964 12.9816 15.5775 18.1552 15.5775C23.3286 15.5775 27.5226 16.3964 27.5226 17.4064C27.5226 18.4165 23.3286 19.2352 18.1552 19.2352ZM28.6735 17.7113C28.6735 16.4088 23.9643 15.353 18.1552 15.353C12.3462 15.353 7.63672 16.4088 7.63672 17.7113C7.63672 18.8583 11.2884 19.8139 16.1273 20.0256V28.4246H20.1075V20.0289C24.9837 19.8238 28.6735 18.8642 28.6735 17.7113Z" fill="white"/>
                        </svg> */}
                        <img src='/images/group_1000007487.png' className='w-[35px] h-auto'/>
                      </div>
                      <Tooltip title="Disbursed Amount">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                          <path d="M12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22ZM12.75 16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16L11.25 11C11.25 10.59 11.59 10.25 12 10.25C12.41 10.25 12.75 10.59 12.75 11L12.75 16ZM11.08 7.62C11.13 7.49 11.2 7.39 11.29 7.29C11.39 7.2 11.5 7.13 11.62 7.08C11.74 7.03 11.87 7 12 7C12.13 7 12.26 7.03 12.38 7.08C12.5 7.13 12.61 7.2 12.71 7.29C12.8 7.39 12.87 7.49 12.92 7.62C12.97 7.74 13 7.87 13 8C13 8.13 12.97 8.26 12.92 8.38C12.87 8.5 12.8 8.61 12.71 8.71C12.61 8.8 12.5 8.87 12.38 8.92C12.14 9.02 11.86 9.02 11.62 8.92C11.5 8.87 11.39 8.8 11.29 8.71C11.2 8.61 11.13 8.5 11.08 8.38C11.03 8.26 11 8.13 11 8C11 7.87 11.03 7.74 11.08 7.62Z" fill="#717184"/>
                        </svg>
                      </Tooltip>
                    </div>
                    <h6>{`$${parseFloat(withdrawal?.disbursed_amount || 0).toFixed(2)}`}</h6>
                    <p>Disbursed Amount</p>
                  </div>
                </Col>
              </Row>
          </div>
        </Col>
      </Row>
        
    </div>
  );
};

export default PayoutCard;
