import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Space, Statistic, Table, Typography,Radio,Row,Col,Image ,Input,Select, Divider} from "antd";
import { useEffect, useState } from "react";
import PageHeader from "../../Components/common/pageHeader";
// import WelcomeBanner from "../../Components/common/WelcomeBanner";
import WelcomeBannerV2 from "../../Components/common/WelcomeBannerV2";
import VideoCard from "../../Components/Cards/videoCard";
import IconCard from "../../Components/Cards/iconCard";

const options = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Videos',
    value: 'videos',
  },
  {
    label: 'E-Book',
    value: 'EBook',
  },
];


function Education() {
  const [value4, setValue4] = useState('all');
  const onChange4 = ({ target: { value } }) => {
    console.log('radio4 checked', value);
    setValue4(value);
  };

  const videData=[
    {
      video:'https://i.vimeocdn.com/video/947735830-7bdb7965ab12f88921e514e0dabc75d638ff7065fa3597d91de1015c970f3866-d?mw=800&mh=1423&q=70',
      text:'How to Grow Your Trader Flow Account to $4 Million',
      img:'/images/video-thumbnail.png',
      category: 'videos'
    },
   
    {
      video:'December Competition',
      text:'How to Grow Your Trader Flow Account to $4 Million',
      img:'/images/video-thumbnail.png',
      category: 'videos'
    },
   
    {
      video:'December Competition',
      text:'How to Grow Your Trader Flow Account to $4 Million',
      img:'/images/video-thumbnail.png',
      category: 'videos'
    },
   
    {
      video:'December Competition',
      text:'Dec 1,2023 - Dec 31,2023',
      img:'/images/video-thumbnail.png',
      category: 'videos'
    },
   
    
    
  ]
  const filteredVideos = videData.filter(data => {
    return value4 === 'all' ? true : data.category.toLowerCase() === value4.toLowerCase();
  });
  
  return (
    <>
      <div className="Competition-page">
        <div className="banner">
          {/* <WelcomeBanner 
            title={'Trader Flow Monthly Competitions'} 
            subheading={`Win big every month in FundedNext's Free Trading Competition! Unleash your trading potential, win cash prizes, and earn challenge accounts. Register now!`}
          /> */}
          <WelcomeBannerV2
            title={'Trader Flow Monthly Competitions'} 
            subheading={`Win big every month in FundedNext's Free Trading Competition! Unleash your trading potential, win cash prizes, and earn challenge accounts. Register now!`}
          />
        </div>

        <div className="header">
          <Row gutter={16} className="w-100">
            <Col xs={24} md={12}>
            {((value4 === 'videos' || value4 === 'all') && value4 !== 'EBook') && (
              <PageHeader
                title={'Educational Videos'}
              />
            )}
            </Col>
            {/* <Col xs={24} md={12} className="right-section"> */}
            <Col xs={24} md={12} className="flex justify-start items-center md:justify-end">
              <Radio.Group
                options={options}
                onChange={onChange4}
                value={value4}
                optionType="button"
                buttonStyle="solid"
                className="customradio"
              />
            </Col>
          </Row>
        </div>

        <Row gutter={30}>
          {
            filteredVideos.map((data,i)=>{
              return(
                <Col xs={24} sm={12} xl={6} key={i}>
                  <VideoCard
                    video={data.video}
                    text={data.text}
                    img={data.img}
                  />
                </Col>
              )
            })
          }
        </Row>

        <Row gutter={30}>
          <Col span={24}>
            <div className="header">
              <Row gutter={16} className="w-100">
                <Col span={24}>
                  {((value4 === 'EBook' || value4 === 'all')) && (
                    <PageHeader
                      title={'E-Books '}
                    />
                  )}
                </Col>
              </Row>    
            </div>
          </Col>
          {((value4 === 'EBook' || value4 === 'all')) && (
            <>
              {/* <Col span={6}> */}
              <Col xs={24} sm={12} xl={6} className="mb-4 sm:mb-0">
                <IconCard
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                    <path d="M18.0622 22.6666H21.958C25.9247 22.6666 26.9163 21.675 26.9163 17.7083V11.7583C26.9163 7.79165 25.9247 6.79998 21.958 6.79998H18.983C17.9913 6.79998 17.7788 6.50248 17.3963 6.00665L15.9088 4.02331C15.3422 3.27248 15.0163 2.83331 13.5288 2.83331H12.0413C8.07467 2.83331 7.08301 3.82498 7.08301 7.79165V17.7083C7.08301 21.675 8.07467 22.6666 12.0413 22.6666H15.9372V26.9166C15.9372 26.9875 15.9655 27.0441 15.9797 27.115C15.243 27.3983 14.648 27.9933 14.3647 28.73C14.2938 28.7158 14.2372 28.6875 14.1663 28.6875H8.49967C7.91884 28.6875 7.43717 29.1691 7.43717 29.75C7.43717 30.3308 7.91884 30.8125 8.49967 30.8125H14.1663C14.2372 30.8125 14.2938 30.7841 14.3647 30.77C14.7755 31.8325 15.7955 32.5833 16.9997 32.5833C18.2038 32.5833 19.2238 31.8325 19.6347 30.77C19.7055 30.7841 19.7622 30.8125 19.833 30.8125H25.4997C26.0805 30.8125 26.5622 30.3308 26.5622 29.75C26.5622 29.1691 26.0805 28.6875 25.4997 28.6875H19.833C19.7622 28.6875 19.7055 28.7158 19.6347 28.73C19.3513 27.9933 18.7563 27.3983 18.0197 27.115C18.0338 27.0441 18.0622 26.9875 18.0622 26.9166V22.6666Z" fill="white"/>
                  </svg>}
                  value={'Prop Trader Secrets'}
                  verticleIcon={'verticleIcon'}
                />
              </Col>
              {/* <Col span={6}> */}
              <Col xs={24} sm={12} xl={6} className="mb-4">
                <IconCard
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                    <path d="M18.0622 22.6666H21.958C25.9247 22.6666 26.9163 21.675 26.9163 17.7083V11.7583C26.9163 7.79165 25.9247 6.79998 21.958 6.79998H18.983C17.9913 6.79998 17.7788 6.50248 17.3963 6.00665L15.9088 4.02331C15.3422 3.27248 15.0163 2.83331 13.5288 2.83331H12.0413C8.07467 2.83331 7.08301 3.82498 7.08301 7.79165V17.7083C7.08301 21.675 8.07467 22.6666 12.0413 22.6666H15.9372V26.9166C15.9372 26.9875 15.9655 27.0441 15.9797 27.115C15.243 27.3983 14.648 27.9933 14.3647 28.73C14.2938 28.7158 14.2372 28.6875 14.1663 28.6875H8.49967C7.91884 28.6875 7.43717 29.1691 7.43717 29.75C7.43717 30.3308 7.91884 30.8125 8.49967 30.8125H14.1663C14.2372 30.8125 14.2938 30.7841 14.3647 30.77C14.7755 31.8325 15.7955 32.5833 16.9997 32.5833C18.2038 32.5833 19.2238 31.8325 19.6347 30.77C19.7055 30.7841 19.7622 30.8125 19.833 30.8125H25.4997C26.0805 30.8125 26.5622 30.3308 26.5622 29.75C26.5622 29.1691 26.0805 28.6875 25.4997 28.6875H19.833C19.7622 28.6875 19.7055 28.7158 19.6347 28.73C19.3513 27.9933 18.7563 27.3983 18.0197 27.115C18.0338 27.0441 18.0622 26.9875 18.0622 26.9166V22.6666Z" fill="white"/>
                  </svg>}
                  value={'Prop Trader Secrets'}
                  verticleIcon={'verticleIcon'}
                />
              </Col>
            </>
          )}
        </Row>
      </div>
    </>
  );
}


export default Education;
