import React from 'react';

const FacebookIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
  <g clip-path="url(#clip0_1_3805)">
    <path d="M22.5999 11.0673C22.5999 4.95404 17.6746 -0.00146484 11.5999 -0.00146484C5.52236 -8.98436e-05 0.597107 4.95404 0.597107 11.0687C0.597107 16.592 4.62036 21.1708 9.87836 22.0013V14.2669H7.08711V11.0687H9.88111V8.62804C9.88111 5.85466 11.5242 4.32291 14.0364 4.32291C15.2409 4.32291 16.499 4.53878 16.499 4.53878V7.26129H15.1116C13.7462 7.26129 13.32 8.11516 13.32 8.99104V11.0673H16.3697L15.883 14.2655H13.3186V21.9999C18.5766 21.1694 22.5999 16.5907 22.5999 11.0673Z" fill="#DD0744"/>
  </g>
  <defs>
    <clipPath id="clip0_1_3805">
      <rect width="22" height="22" fill="white" transform="translate(0.599609)"/>
    </clipPath>
  </defs>
</svg>
  
);

export default FacebookIcon;