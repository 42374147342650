import "./styles/App.scss";
import "./styles/fontawesome/css/all.css";
import "./styles/global.css";

import {
  AffiliatePortal,
  Billing,
  Certificate,
  Competition,
  Dashboard,
  Education,
  Faq,
  Matrix,
  Payout,
  Reset,
  TradingJournal,
  TradingPlatform,
} from "Components/icons/SideMenuIcon";
import { useCallback, useState } from "react";

import AppHeader from "./Components/Header";
import PageContent from "./Components/PageContent";
import SideMenu from "./Components/SideMenu";
import { useLocation } from "react-router-dom";
import MetaPixel from './utils/MetaPixel';

function App() {
  const initialNavigation = [
    { name: "Dashboard", href: "/", icon: Dashboard, current: false },
    { name: "Matrix", href: "/matrix", icon: Matrix, current: false },
    {
      name: "Trading Journal",
      href: "/trading-journal",
      icon: TradingJournal,
      current: false,
      child: ["/trade-detail"]
    },
    {
      name: "Trading Platform",
      href: "/trading-platform",
      icon: TradingPlatform,
      current: false,
    },
    {
      name: "Billing",
      href: "/challenge/purchase",
      icon: Billing,
      current: false,
    },
    { name: "Reset", href: "/reset", icon: Reset, current: false },
    { name: "Payout", href: "/payout", icon: Payout, current: false },
    {
      name: "Certificate",
      href: "/certificate",
      icon: Certificate,
      current: false,
    },
    // {
    //   name: "Competition",
    //   href: "/competition",
    //   icon: Competition,
    //   current: false,
    // },
    // { name: "Education", href: "/education", icon: Education, current: false },
    {
      name: "Affiliate Portal",
      href: "/affiliate-portal",
      icon: AffiliatePortal,
      current: false,
    },
    { name: "FAQ", href: "/faq", icon: Faq, current: false },
  ];

  const location = useLocation();
  const isAuthPage = location.pathname.startsWith("/auth");
  const isActivePath = useCallback(
    
    (navItem) => {
      if (navItem.href === "/") {
        return location.pathname === "/";
      }
      
      let navItemChild = navItem?.child ?? [];
      if (navItemChild.length > 0) {
        if (navItemChild.some(path => location.pathname.startsWith(path))) {
          return true;
        }
        return location.pathname.startsWith(navItem.href);
      } else {
        return location.pathname.startsWith(navItem.href);
      }
      
    },
    [location.pathname]
  );
  const currentNavItem =
    initialNavigation.find(isActivePath) || initialNavigation[0];
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const notLoggedInPageList = ["/purchase-challenge"];

  return (
    <>
      <MetaPixel />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=111838808562412&ev=PageView&noscript=1"
        />
      </noscript>
      <div className="wrapper">
        {notLoggedInPageList.includes(location.pathname) ? (
          <PageContent />
        ) : (
          <>
            {!isAuthPage && <AppHeader setSidebarOpen={setSidebarOpen} />}
            <div className="SideMenuAndPageContent">
              {!isAuthPage && (
                <SideMenu
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen}
                  navigation={initialNavigation}
                  currentNavItem={currentNavItem}
                />
              )}
              {!isAuthPage ? (
                <PageContent sidebarOpen={sidebarOpen} css={true} />
              ) : (
                <PageContent />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default App;
